export enum MethodsEnum {
    map = 1,
    gps = 2,
    kml = 3
}

export enum ValidTypeEnum {
    valid = 1,
    invalid = 2,
    unknown = 3
}
