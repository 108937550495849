import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { select, Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { Chart, registerables } from 'chart.js';
import { DeviceDetectorService } from 'ngx-device-detector';
import { forkJoin, Subscription } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { CorePartialState } from 'src/app/core/state/core.reducer';
import { IDateRangeSelectorForm } from 'src/app/pages/dashboard/models/agromonitoring-data.model';
import {
  ChartNameEnum,
  ChartResolutionMap,
  ChartSelectedResolutionMap,
  GatewayCumulativeWaterAmountViewModel,
  GatewayHumidityViewModel,
  GatewayPressureViewModel,
  GatewayTemperatureViewModel,
  GatewayUviViewModel,
  GatewayWaterAmountViewModel,
  GatewayWindSpeedViewModel,
  Location,
  SensorDataResolutionEnum,
  SoilMoistureViewModel,
  SoilTemperatureViewModel,
} from 'src/app/pages/dashboard/models/sensor-data.model';
import { GatewayDataService } from 'src/app/pages/dashboard/services/gateway-data.service';
import { NodeDataService } from 'src/app/pages/dashboard/services/node-data.service';
import { SensorDataService } from 'src/app/pages/dashboard/services/sensor-data.service';
import { DashboardPartialState } from 'src/app/pages/dashboard/state/dashboard.reducer';
import { DeviceTypeEnum } from 'src/app/shared/enums/device-type.enum';
import * as CoreQueries from '../../../../../../core/state/core.selectors';
import * as DashboardQueries from '../../../../state/dashboard.selectors';

@Component({
  selector: 'dfarm-sensor-viewer-details',
  templateUrl: './sensor-viewer-details.component.html',
  styleUrls: ['./sensor-viewer-details.component.scss'],
})
export class SensorViewerDetailsComponent implements OnInit, OnChanges {
  temperatureChart: any;
  waterAmountChart: any;
  cumulativeWaterAmountChart: any;
  humidityChart: any;
  pressureChart: any;
  windSpeedChart: any;
  uviChart: any;
  soilTemperatureChart: any;
  soilMoistureChart: any;
  subs: Subscription[] = [];
  sensorViewerPanelIsOpen = false;
  formControls: Record<keyof IDateRangeSelectorForm, FormControl> = {
    startDate: new FormControl(new Date()),
    endDate: new FormControl(new Date()),
  };
  dateRangeSelectorForm = new FormGroup(this.formControls);
  @Input() intervalStart: Date;
  @Input() intervalEnd: Date;
  @Input() maxDate: string;
  @Output() intervalStartChange = new EventEmitter<Date>();
  @Output() intervalEndChange = new EventEmitter<Date>();

  // @ViewChild('soilConductivityCanvas', { static: true })
  // private soilConductivityCanvas: ElementRef;
  // soilConductivityChart: any;
  intervalStartChangeEmitted = false;
  intervalEndChangeEmitted = false;
  @Input() hasSensorDataLocation = false;
  gatewayTemperatureData: GatewayTemperatureViewModel;
  soilTemperatureData: SoilTemperatureViewModel;
  gatewayWaterAmountData: GatewayWaterAmountViewModel;
  gatewayCumulativeWaterAmountData: GatewayCumulativeWaterAmountViewModel;
  soilMoistureData: SoilMoistureViewModel;
  gatewayHumidityData: GatewayHumidityViewModel;
  gatewayPressureData: GatewayPressureViewModel;
  gatewayWindSpeedData: GatewayWindSpeedViewModel;
  gatewayUviData: GatewayUviViewModel;
  selectedSensorDataLocation: Location & { deviceType: DeviceTypeEnum } = {
    id: undefined,
    coordinate: undefined,
    deviceType: undefined,
  };
  DeviceTypeEnum = DeviceTypeEnum;
  chartNameEnum = ChartNameEnum;
  chartResolutionMap: ChartResolutionMap[] = [];
  chartSelectedResolutionMap: ChartSelectedResolutionMap[] = [];
  // Gateway chartok
  @ViewChild('temperatureCanvas', { static: true })
  private temperatureCanvas: ElementRef;
  @ViewChild('waterAmountCanvas', { static: true })
  private waterAmountCanvas: ElementRef;
  @ViewChild('cumulativeWaterAmountCanvas', { static: true })
  private cumulativeWaterAmountCanvas: ElementRef;
  @ViewChild('humidityCanvas', { static: true })
  private humidityCanvas: ElementRef;
  @ViewChild('pressureCanvas', { static: true })
  private pressureCanvas: ElementRef;
  @ViewChild('windSpeedCanvas', { static: true })
  private windSpeedCanvas: ElementRef;
  @ViewChild('uviCanvas', { static: true })
  private uviCanvas: ElementRef;
  // Ez a három chart kell Node-hoz is és Gateway-hez is
  @ViewChild('soilTemperatureCanvas', { static: true })
  private soilTemperatureCanvas: ElementRef;
  @ViewChild('soilMoistureCanvas', { static: true })
  private soilMoistureCanvas: ElementRef;

  constructor(
    private readonly coreStore: Store<CorePartialState>,
    private readonly dashboardStore: Store<DashboardPartialState>,
    private readonly deviceDetectorService: DeviceDetectorService,
    private readonly sensorDataService: SensorDataService,
    private readonly gatewayDataService: GatewayDataService,
    private readonly nodeDataService: NodeDataService,
    private readonly translateService: TranslateService,
  ) {}

  ngOnChanges(changes: SimpleChanges): void {
    for (const propName in changes) {
      const changedProp = changes[propName];

      if (propName === 'intervalStart' && changedProp.currentValue !== undefined) {
        if (!this.intervalStartChangeEmitted) {
          this.formControls.startDate.setValue(this.intervalStart.toISOString().slice(0, 10), {
            emitEvent: false,
          });
        } else {
          this.intervalStartChangeEmitted = false;
        }
      } else if (propName === 'intervalEnd' && changedProp.currentValue !== undefined) {
        if (!this.intervalEndChangeEmitted) {
          this.formControls.endDate.setValue(this.intervalEnd.toISOString().slice(0, 10), {
            emitEvent: false,
          });
        } else {
          this.intervalEndChangeEmitted = false;
        }
      }
    }
  }

  ngOnInit(): void {
    this.chartResolutionMap = this.sensorDataService.getChartResolutionMap();
    this.chartSelectedResolutionMap = this.sensorDataService.getChartSelectedResolutionMap();

    this.subs.push(
      this.dateRangeSelectorForm.valueChanges.pipe(debounceTime(1000)).subscribe(() => {
        if (
          new Date(this.formControls.startDate.value).setHours(0, 0, 0, 0) !== this.intervalStart.setHours(0, 0, 0, 0)
        ) {
          this.intervalStart = new Date(this.formControls.startDate.value);
          this.intervalStartChange.emit(this.intervalStart);
          this.intervalStartChangeEmitted = true;
        }

        if (new Date(this.formControls.endDate.value).setHours(0, 0, 0, 0) !== this.intervalEnd.setHours(0, 0, 0, 0)) {
          this.intervalEnd = new Date(this.formControls.endDate.value);
          this.intervalEndChange.emit(this.intervalEnd);
          this.intervalEndChangeEmitted = true;
        }
      }),
    );

    this.subs.push(
      this.coreStore.pipe(select(CoreQueries.getSensorViewerPanelIsOpen)).subscribe(panelIsOpen => {
        this.sensorViewerPanelIsOpen = panelIsOpen;
        if (panelIsOpen) {
          this.getGatewayAndNodeData();
        } else {
          this.clearAllCharts();
        }
      }),
    );

    this.subs.push(
      this.dashboardStore.pipe(select(DashboardQueries.getSelectedSensorDataLocation)).subscribe(sensorDataLocation => {
        this.clearAllCharts();

        if (sensorDataLocation !== undefined && sensorDataLocation.id !== undefined) {
          this.selectedSensorDataLocation = sensorDataLocation;
        } else {
          this.selectedSensorDataLocation = {
            id: undefined,
            coordinate: undefined,
            deviceType: undefined,
          };
        }

        if (this.sensorViewerPanelIsOpen) {
          this.getGatewayAndNodeData();
        }
      }),
    );

    Chart.register(...registerables);

    this.temperatureChart = new Chart(this.temperatureCanvas.nativeElement, {
      type: 'line',
      data: {
        datasets: [],
      },
      options: {
        interaction: {
          intersect: false,
          mode: 'index',
        },
        scales: {
          x: {
            offset: true,
            ticks: {
              display: !this.deviceDetectorService.isMobile(),
              color: 'rgba(255, 255, 255, 0.75)',
            },
            title: {
              display: false,
              text: this.translateService.instant('dashboard-page.sensor-viewer-panel.date'),
              color: 'rgba(255, 255, 255, 0.75)',
            },
          },
          y: {
            ticks: {
              color: 'rgba(255, 255, 255, 0.75)',
            },
            title: {
              display: false,
              text: this.translateService.instant('dashboard-page.sensor-viewer-panel.temperature-with-degree'),
              color: 'rgba(255, 255, 255, 0.75)',
            },
            grid: {
              drawBorder: false,
              color: function (context) {
                return 'black';
              },
            },
          },
        },
        plugins: {
          title: {
            display: true,
            text: this.translateService.instant('dashboard-page.sensor-viewer-panel.air-temperature'),
            color: 'white',
            font: {
              size: 14,
            },
            padding: {
              bottom: 5,
            },
          },
          legend: {
            display: !this.deviceDetectorService.isMobile(),
            labels: {
              color: 'rgba(255, 255, 255, 0.75)',
            },
          },
        },
        layout: {
          padding: 10,
        },
      },
    });

    this.waterAmountChart = new Chart(this.waterAmountCanvas.nativeElement, {
      type: 'bar',
      data: {
        datasets: [],
      },
      options: {
        interaction: {
          intersect: false,
          mode: 'index',
        },
        scales: {
          x: {
            offset: true,
            ticks: {
              display: !this.deviceDetectorService.isMobile(),
              color: 'rgba(255, 255, 255, 0.75)',
            },
            title: {
              display: false,
              text: this.translateService.instant('dashboard-page.sensor-viewer-panel.date'),
              color: 'rgba(255, 255, 255, 0.75)',
            },
          },
          y: {
            min: 0,
            ticks: {
              color: 'rgba(255, 255, 255, 0.75)',
            },
            title: {
              display: false,
              text: this.translateService.instant('dashboard-page.sensor-viewer-panel.amount'),
              color: 'rgba(255, 255, 255, 0.75)',
            },
            grid: {
              drawBorder: false,
              color: function (context) {
                return 'black';
              },
            },
          },
        },
        plugins: {
          title: {
            display: true,
            text: this.translateService.instant('dashboard-page.sensor-viewer-panel.water-mm'),
            color: 'white',
            font: {
              size: 14,
            },
            padding: {
              bottom: 5,
            },
          },
          legend: {
            display: !this.deviceDetectorService.isMobile(),
            labels: {
              color: 'rgba(255, 255, 255, 0.75)',
            },
          },
        },
        layout: {
          padding: 10,
        },
      },
    });

    this.cumulativeWaterAmountChart = new Chart(this.cumulativeWaterAmountCanvas.nativeElement, {
      type: 'line',
      data: {
        datasets: [],
      },
      options: {
        interaction: {
          intersect: false,
          mode: 'index',
        },
        scales: {
          x: {
            offset: true,
            ticks: {
              display: !this.deviceDetectorService.isMobile(),
              color: 'rgba(255, 255, 255, 0.75)',
            },
            title: {
              display: false,
              text: this.translateService.instant('dashboard-page.sensor-viewer-panel.date'),
              color: 'rgba(255, 255, 255, 0.75)',
            },
          },
          y: {
            min: 0,
            ticks: {
              color: 'rgba(255, 255, 255, 0.75)',
            },
            title: {
              display: false,
              text: this.translateService.instant('dashboard-page.sensor-viewer-panel.amount'),
              color: 'rgba(255, 255, 255, 0.75)',
            },
            grid: {
              drawBorder: false,
              color: function (context) {
                return 'black';
              },
            },
          },
        },
        plugins: {
          title: {
            display: true,
            text: this.translateService.instant('dashboard-page.sensor-viewer-panel.cumulative-water-mm'),
            color: 'white',
            font: {
              size: 14,
            },
            padding: {
              bottom: 5,
            },
          },
          legend: {
            display: !this.deviceDetectorService.isMobile(),
            labels: {
              color: 'rgba(255, 255, 255, 0.75)',
            },
          },
        },
        layout: {
          padding: 10,
        },
      },
    });

    this.humidityChart = new Chart(this.humidityCanvas.nativeElement, {
      type: 'line',
      data: {
        datasets: [],
      },
      options: {
        interaction: {
          intersect: false,
          mode: 'index',
        },
        scales: {
          x: {
            offset: true,
            ticks: {
              display: !this.deviceDetectorService.isMobile(),
              color: 'rgba(255, 255, 255, 0.75)',
            },
            title: {
              display: false,
              text: this.translateService.instant('dashboard-page.sensor-viewer-panel.date'),
              color: 'rgba(255, 255, 255, 0.75)',
            },
          },
          y: {
            min: 0,
            ticks: {
              color: 'rgba(255, 255, 255, 0.75)',
            },
            title: {
              display: false,
              text: this.translateService.instant('dashboard-page.sensor-viewer-panel.humidity'),
              color: 'rgba(255, 255, 255, 0.75)',
            },
            grid: {
              drawBorder: false,
              color: function (context) {
                return 'black';
              },
            },
          },
        },
        plugins: {
          title: {
            display: true,
            text: this.translateService.instant('dashboard-page.sensor-viewer-panel.humidity'),
            color: 'white',
            font: {
              size: 14,
            },
            padding: {
              bottom: 5,
            },
          },
          legend: {
            display: !this.deviceDetectorService.isMobile(),
            labels: {
              color: 'rgba(255, 255, 255, 0.75)',
            },
          },
        },
        layout: {
          padding: 10,
        },
      },
    });

    this.pressureChart = new Chart(this.pressureCanvas.nativeElement, {
      type: 'line',
      data: {
        datasets: [],
      },
      options: {
        interaction: {
          intersect: false,
          mode: 'index',
        },
        scales: {
          x: {
            offset: true,
            ticks: {
              display: !this.deviceDetectorService.isMobile(),
              color: 'rgba(255, 255, 255, 0.75)',
            },
            title: {
              display: false,
              text: this.translateService.instant('dashboard-page.sensor-viewer-panel.date'),
              color: 'rgba(255, 255, 255, 0.75)',
            },
          },
          y: {
            min: 0,
            ticks: {
              color: 'rgba(255, 255, 255, 0.75)',
            },
            title: {
              display: false,
              text: this.translateService.instant('dashboard-page.sensor-viewer-panel.pressure'),
              color: 'rgba(255, 255, 255, 0.75)',
            },
            grid: {
              drawBorder: false,
              color: function (context) {
                return 'black';
              },
            },
          },
        },
        plugins: {
          title: {
            display: true,
            text: this.translateService.instant('dashboard-page.sensor-viewer-panel.pressure'),
            color: 'white',
            font: {
              size: 14,
            },
            padding: {
              bottom: 5,
            },
          },
          legend: {
            display: !this.deviceDetectorService.isMobile(),
            labels: {
              color: 'rgba(255, 255, 255, 0.75)',
            },
          },
        },
        layout: {
          padding: 10,
        },
      },
    });

    this.windSpeedChart = new Chart(this.windSpeedCanvas.nativeElement, {
      type: 'bar',
      data: {
        datasets: [],
      },
      options: {
        interaction: {
          intersect: false,
          mode: 'index',
        },
        scales: {
          x: {
            offset: true,
            ticks: {
              display: !this.deviceDetectorService.isMobile(),
              color: 'rgba(255, 255, 255, 0.75)',
            },
            title: {
              display: false,
              text: this.translateService.instant('dashboard-page.sensor-viewer-panel.date'),
              color: 'rgba(255, 255, 255, 0.75)',
            },
          },
          y: {
            min: 0,
            ticks: {
              color: 'rgba(255, 255, 255, 0.75)',
            },
            title: {
              display: false,
              text: this.translateService.instant('dashboard-page.sensor-viewer-panel.temperature-with-degree'),
              color: 'rgba(255, 255, 255, 0.75)',
            },
            grid: {
              drawBorder: false,
              color: function (context) {
                return 'black';
              },
            },
          },
        },
        plugins: {
          title: {
            display: true,
            text: this.translateService.instant('dashboard-page.sensor-viewer-panel.wind'),
            color: 'white',
            font: {
              size: 14,
            },
            padding: {
              bottom: 5,
            },
          },
          legend: {
            display: !this.deviceDetectorService.isMobile(),
            labels: {
              color: 'rgba(255, 255, 255, 0.75)',
            },
          },
        },
        layout: {
          padding: 10,
        },
      },
    });

    this.uviChart = new Chart(this.uviCanvas.nativeElement, {
      type: 'line',
      data: {
        datasets: [],
      },
      options: {
        interaction: {
          intersect: false,
          mode: 'index',
        },
        scales: {
          x: {
            offset: true,
            ticks: {
              display: !this.deviceDetectorService.isMobile(),
              color: 'rgba(255, 255, 255, 0.75)',
            },
            title: {
              display: false,
              text: this.translateService.instant('dashboard-page.sensor-viewer-panel.date'),
              color: 'rgba(255, 255, 255, 0.75)',
            },
          },
          y: {
            min: 0,
            ticks: {
              color: 'rgba(255, 255, 255, 0.75)',
            },
            title: {
              display: false,
              text: this.translateService.instant('dashboard-page.sensor-viewer-panel.uvi'),
              color: 'rgba(255, 255, 255, 0.75)',
            },
            grid: {
              drawBorder: false,
              color: function (context) {
                return 'black';
              },
            },
          },
        },
        plugins: {
          title: {
            display: true,
            text: this.translateService.instant('dashboard-page.sensor-viewer-panel.uvi'),
            color: 'white',
            font: {
              size: 14,
            },
            padding: {
              bottom: 5,
            },
          },
          legend: {
            display: !this.deviceDetectorService.isMobile(),
            labels: {
              color: 'rgba(255, 255, 255, 0.75)',
            },
          },
        },
        layout: {
          padding: 10,
        },
      },
    });

    this.soilTemperatureChart = new Chart(this.soilTemperatureCanvas.nativeElement, {
      type: 'line',
      data: {
        datasets: [],
      },
      options: {
        interaction: {
          intersect: false,
          mode: 'index',
        },
        scales: {
          x: {
            offset: true,
            ticks: {
              display: !this.deviceDetectorService.isMobile(),
              color: 'rgba(255, 255, 255, 0.75)',
            },
            title: {
              display: false,
              text: this.translateService.instant('dashboard-page.sensor-viewer-panel.date'),
              color: 'rgba(255, 255, 255, 0.75)',
            },
          },
          y: {
            ticks: {
              color: 'rgba(255, 255, 255, 0.75)',
            },
            title: {
              display: false,
              text: this.translateService.instant('dashboard-page.sensor-viewer-panel.soil-temperature-with-degree'),
              color: 'rgba(255, 255, 255, 0.75)',
            },
            grid: {
              drawBorder: false,
              color: function (context) {
                return 'black';
              },
            },
          },
        },
        plugins: {
          title: {
            display: true,
            text: this.translateService.instant('dashboard-page.sensor-viewer-panel.soil-temperature-with-degree'),
            color: 'white',
            font: {
              size: 14,
            },
            padding: {
              bottom: 5,
            },
          },
          legend: {
            display: !this.deviceDetectorService.isMobile(),
            labels: {
              color: 'rgba(255, 255, 255, 0.75)',
            },
          },
        },
        layout: {
          padding: 10,
        },
      },
    });

    this.soilMoistureChart = new Chart(this.soilMoistureCanvas.nativeElement, {
      type: 'line',
      data: {
        datasets: [],
      },
      options: {
        interaction: {
          intersect: false,
          mode: 'index',
        },
        scales: {
          x: {
            offset: true,
            ticks: {
              display: !this.deviceDetectorService.isMobile(),
              color: 'rgba(255, 255, 255, 0.75)',
            },
            title: {
              display: false,
              text: this.translateService.instant('dashboard-page.sensor-viewer-panel.date'),
              color: 'rgba(255, 255, 255, 0.75)',
            },
          },
          y: {
            min: 0,
            ticks: {
              color: 'rgba(255, 255, 255, 0.75)',
            },
            title: {
              display: false,
              text: this.translateService.instant('dashboard-page.sensor-viewer-panel.soil-moisture-with-percent'),
              color: 'rgba(255, 255, 255, 0.75)',
            },
            grid: {
              drawBorder: false,
              color: function (context) {
                return 'black';
              },
            },
          },
        },
        plugins: {
          title: {
            display: true,
            text: this.translateService.instant('dashboard-page.sensor-viewer-panel.soil-moisture-with-percent'),
            color: 'white',
            font: {
              size: 14,
            },
            padding: {
              bottom: 5,
            },
          },
          legend: {
            display: !this.deviceDetectorService.isMobile(),
            labels: {
              color: 'rgba(255, 255, 255, 0.75)',
            },
          },
        },
        layout: {
          padding: 10,
        },
      },
    });

    // this.soilConductivityChart = new Chart(
    //   this.soilConductivityCanvas.nativeElement,
    //   {
    //     type: 'line',
    //     data: {
    //       datasets: [],
    //     },
    //     options: {
    //       interaction: {
    //         intersect: false,
    //         mode: 'index',
    //       },
    //       scales: {
    //         x: {
    //           offset: true,
    //           ticks: {
    //             display: !this.deviceDetectorService.isMobile(),
    //             color: 'rgba(255, 255, 255, 0.75)',
    //           },
    //           title: {
    //             display: false,
    //             text: this.translateService.instant(
    //               'dashboard-page.sensor-viewer-panel.date'
    //             ),
    //             color: 'rgba(255, 255, 255, 0.75)',
    //           },
    //         },
    //         y: {
    //           min: 0,
    //           max: 100,
    //           ticks: {
    //             color: 'rgba(255, 255, 255, 0.75)',
    //           },
    //           title: {
    //             display: false,
    //             text: this.translateService.instant(
    //               'dashboard-page.sensor-viewer-panel.soil-moisture-with-percent'
    //             ),
    //             color: 'rgba(255, 255, 255, 0.75)',
    //           },
    //           grid: {
    //             drawBorder: false,
    //             color: function (context) {
    //               return 'black';
    //             },
    //           },
    //         },
    //       },
    //       plugins: {
    //         title: {
    //           display: true,
    //           text: this.translateService.instant(
    //             'dashboard-page.sensor-viewer-panel.soil-moisture-with-percent'
    //           ),
    //           color: 'white',
    //           font: {
    //             size: 14,
    //           },
    //           padding: {
    //             bottom: 5,
    //           },
    //         },
    //         legend: {
    //           display: !this.deviceDetectorService.isMobile(),
    //           labels: {
    //             color: 'rgba(255, 255, 255, 0.75)',
    //           },
    //         },
    //       },
    //       layout: {
    //         padding: 10,
    //       },
    //     },
    //   }
    // );
  }

  changeChartResolution(sensorDataResolutionEnum: SensorDataResolutionEnum, chartNameEnum: ChartNameEnum): void {
    const intervalStart = new Date(this.formControls.startDate.value);
    const intervalEnd = new Date(this.formControls.endDate.value);
    intervalStart.setHours(0, 0, 0, 0);
    intervalEnd.setHours(23, 59, 0, 0);

    switch (chartNameEnum.valueOf()) {
      case ChartNameEnum.temperatureCanvas.valueOf():
        const temperatureChartResolution = this.chartSelectedResolutionMap.find(x => x.chartName === chartNameEnum);
        temperatureChartResolution.selectedResolution = sensorDataResolutionEnum;

        this.gatewayDataService
          .getGatewayTemperature(
            this.selectedSensorDataLocation.id,
            intervalStart,
            intervalEnd,
            temperatureChartResolution.selectedResolution,
          )
          .subscribe(gatewayTemperature => {
            this.gatewayTemperatureData = gatewayTemperature;
            this.clearTemperatureChart();
            this.updateTemperatureChart(temperatureChartResolution.selectedResolution);
          });
        break;

      case ChartNameEnum.soilTemperatureCanvas.valueOf():
        const soilTemperatureChartResolution = this.chartSelectedResolutionMap.find(x => x.chartName === chartNameEnum);
        soilTemperatureChartResolution.selectedResolution = sensorDataResolutionEnum;

        if (this.selectedSensorDataLocation.deviceType === DeviceTypeEnum.Gateway) {
          this.gatewayDataService
            .getGatewaySoilTemperature(
              this.selectedSensorDataLocation.id,
              intervalStart,
              intervalEnd,
              soilTemperatureChartResolution.selectedResolution,
            )
            .subscribe(gatewaySoilTemperature => {
              this.soilTemperatureData = gatewaySoilTemperature;
              this.clearSoilTemperatureChart();
              this.updateSoilTemperatureChart(soilTemperatureChartResolution.selectedResolution);
            });
        } else if (this.selectedSensorDataLocation.deviceType === DeviceTypeEnum.Node) {
          this.nodeDataService
            .getNodeSoilTemperature(
              this.selectedSensorDataLocation.id,
              intervalStart,
              intervalEnd,
              soilTemperatureChartResolution.selectedResolution,
            )
            .subscribe(nodeSoilTemperature => {
              this.soilTemperatureData = nodeSoilTemperature;
              this.clearSoilTemperatureChart();
              this.updateSoilTemperatureChart(soilTemperatureChartResolution.selectedResolution);
            });
        }
        break;

      case ChartNameEnum.waterAmountCanvas.valueOf():
        const waterAmountChartResolution = this.chartSelectedResolutionMap.find(x => x.chartName === chartNameEnum);
        waterAmountChartResolution.selectedResolution = sensorDataResolutionEnum;

        this.gatewayDataService
          .getGatewayWaterAmount(
            this.selectedSensorDataLocation.id,
            intervalStart,
            intervalEnd,
            waterAmountChartResolution.selectedResolution,
          )
          .subscribe(gatewayWaterAmount => {
            this.gatewayWaterAmountData = gatewayWaterAmount;
            this.clearWaterAmountChart();
            this.updateWaterAmountChart(waterAmountChartResolution.selectedResolution);
          });
        break;

      case ChartNameEnum.cumulativeWaterAmountCanvas.valueOf():
        const cumulativeWaterAmountChartResolution = this.chartSelectedResolutionMap.find(
          x => x.chartName === chartNameEnum,
        );
        cumulativeWaterAmountChartResolution.selectedResolution = sensorDataResolutionEnum;

        this.gatewayDataService
          .getGatewayCumulativeWaterAmount(
            this.selectedSensorDataLocation.id,
            intervalStart,
            intervalEnd,
            cumulativeWaterAmountChartResolution.selectedResolution,
          )
          .subscribe(gatewayCumulativeWaterAmount => {
            this.gatewayCumulativeWaterAmountData = gatewayCumulativeWaterAmount;
            this.clearCumulativeWaterAmountChart();
            this.updateCumulativeWaterAmountChart(cumulativeWaterAmountChartResolution.selectedResolution);
          });
        break;

      case ChartNameEnum.soilMoistureCanvas.valueOf():
        const soilMoistureChartResolution = this.chartSelectedResolutionMap.find(x => x.chartName === chartNameEnum);
        soilMoistureChartResolution.selectedResolution = sensorDataResolutionEnum;

        if (this.selectedSensorDataLocation.deviceType === DeviceTypeEnum.Gateway) {
          this.gatewayDataService
            .getGatewaySoilMoisture(
              this.selectedSensorDataLocation.id,
              intervalStart,
              intervalEnd,
              soilMoistureChartResolution.selectedResolution,
            )
            .subscribe(gatewaySoilMoisture => {
              this.soilMoistureData = gatewaySoilMoisture;
              this.clearSoilMoistureChart();
              this.updateSoilMoistureChart(soilMoistureChartResolution.selectedResolution);
            });
        } else if (this.selectedSensorDataLocation.deviceType === DeviceTypeEnum.Node) {
          this.nodeDataService
            .getNodeSoilMoisture(
              this.selectedSensorDataLocation.id,
              intervalStart,
              intervalEnd,
              sensorDataResolutionEnum,
            )
            .subscribe(nodeSoilMoisture => {
              this.soilMoistureData = nodeSoilMoisture;
              this.clearSoilMoistureChart();
              this.updateSoilMoistureChart(sensorDataResolutionEnum);
            });
        }
        break;

      case ChartNameEnum.humidityCanvas.valueOf():
        const humidityChartResolution = this.chartSelectedResolutionMap.find(x => x.chartName === chartNameEnum);
        humidityChartResolution.selectedResolution = sensorDataResolutionEnum;

        this.gatewayDataService
          .getGatewayHumidity(
            this.selectedSensorDataLocation.id,
            intervalStart,
            intervalEnd,
            humidityChartResolution.selectedResolution,
          )
          .subscribe(gatewayHumidity => {
            this.gatewayHumidityData = gatewayHumidity;
            this.clearHumidityChart();
            this.updateHumidityChart(humidityChartResolution.selectedResolution);
          });
        break;

      case ChartNameEnum.pressureCanvas.valueOf():
        const pressureChartResolution = this.chartSelectedResolutionMap.find(x => x.chartName === chartNameEnum);
        pressureChartResolution.selectedResolution = sensorDataResolutionEnum;

        this.gatewayDataService
          .getGatewayPressure(
            this.selectedSensorDataLocation.id,
            intervalStart,
            intervalEnd,
            pressureChartResolution.selectedResolution,
          )
          .subscribe(gatewayPressure => {
            this.gatewayPressureData = gatewayPressure;
            this.clearPressureChart();
            this.updatePressureChart(pressureChartResolution.selectedResolution);
          });
        break;

      case ChartNameEnum.windSpeedCanvas.valueOf():
        const windSpeedChartResolution = this.chartSelectedResolutionMap.find(x => x.chartName === chartNameEnum);
        windSpeedChartResolution.selectedResolution = sensorDataResolutionEnum;

        this.gatewayDataService
          .getGatewayWindSpeed(
            this.selectedSensorDataLocation.id,
            intervalStart,
            intervalEnd,
            windSpeedChartResolution.selectedResolution,
          )
          .subscribe(gatewayWindSpeed => {
            this.gatewayWindSpeedData = gatewayWindSpeed;
            this.clearWindSpeedChart();
            this.updateWindSpeedChart(windSpeedChartResolution.selectedResolution);
          });
        break;

      case ChartNameEnum.uviCanvas.valueOf():
        const uviChartResolution = this.chartSelectedResolutionMap.find(x => x.chartName === chartNameEnum);
        uviChartResolution.selectedResolution = sensorDataResolutionEnum;

        this.gatewayDataService
          .getGatewayUvi(
            this.selectedSensorDataLocation.id,
            intervalStart,
            intervalEnd,
            uviChartResolution.selectedResolution,
          )
          .subscribe(gatewayUvi => {
            this.gatewayUviData = gatewayUvi;
            this.clearUviChart();
            this.updateUviChart(uviChartResolution.selectedResolution);
          });
        break;

      // case ChartNameEnum.soilConductivityCanvas.valueOf():
      // const soilConductivityChartResolution = this.chartSelectedResolutionMap.find((x) => x.chartName === chartNameEnum);
      // soilConductivityChartResolution.selectedResolution = sensorDataResolutionEnum;

      // if (this.selectedSensorDevice.deviceType === DeviceTypeEnum.Gateway) {
      //   this.gatewayDataService
      //     .getGatewaySoilConductivity(
      //       this.selectedSensorDevice.id,
      //       intervalStart,
      //       intervalEnd,
      //       soilConductivityChartResolution.selectedResolution
      //     )
      //     .subscribe((gatewaySoilConductivity) => {
      //       // this.updateSoilConductivityChart();
      //     });
      // } else {
      //   this.nodeDataService
      //     .getNodeSoilConductivity(
      //       this.selectedSensorDevice.id,
      //       intervalStart,
      //       intervalEnd,
      //       soilConductivityChartResolution.selectedResolution
      //     )
      //     .subscribe((nodeSoilConductivity) => {
      //       // this.updateSoilConductivityChart();
      //     });
      // }
      // break;

      default:
        break;
    }
  }

  getChartResolution(chartNameEnum: ChartNameEnum): ChartResolutionMap {
    return this.chartResolutionMap.find(x => x.chartName === chartNameEnum);
  }

  private getGatewayAndNodeData(): void {
    if (
      this.dateRangeSelectorForm.valid &&
      this.selectedSensorDataLocation !== undefined &&
      this.selectedSensorDataLocation.coordinate !== undefined
    ) {
      const intervalStart = new Date(this.formControls.startDate.value);
      const intervalEnd = new Date(this.formControls.endDate.value);
      intervalStart.setHours(0, 0, 0, 0);
      intervalEnd.setHours(23, 59, 0, 0);

      if (this.selectedSensorDataLocation.deviceType === DeviceTypeEnum.Gateway) {
        this.getGatewayData(intervalStart, intervalEnd);
      } else if (this.selectedSensorDataLocation.deviceType === DeviceTypeEnum.Node) {
        this.getNodeData(intervalStart, intervalEnd);
      }
    }
  }

  private getGatewayData(intervalStart: Date, intervalEnd: Date): void {
    const temperatureChartResolution = this.chartSelectedResolutionMap.find(
      x => x.chartName === ChartNameEnum.temperatureCanvas,
    ).selectedResolution;
    const soilTemperatureChartResolution = this.chartSelectedResolutionMap.find(
      x => x.chartName === ChartNameEnum.soilTemperatureCanvas,
    ).selectedResolution;
    const waterAmountChartResolution = this.chartSelectedResolutionMap.find(
      x => x.chartName === ChartNameEnum.waterAmountCanvas,
    ).selectedResolution;
    const soilMoistureChartResolution = this.chartSelectedResolutionMap.find(
      x => x.chartName === ChartNameEnum.soilMoistureCanvas,
    ).selectedResolution;
    const cumulativeWaterAmountChartResolution = this.chartSelectedResolutionMap.find(
      x => x.chartName === ChartNameEnum.cumulativeWaterAmountCanvas,
    ).selectedResolution;
    const humidityChartResolution = this.chartSelectedResolutionMap.find(
      x => x.chartName === ChartNameEnum.humidityCanvas,
    ).selectedResolution;
    const pressureChartResolution = this.chartSelectedResolutionMap.find(
      x => x.chartName === ChartNameEnum.pressureCanvas,
    ).selectedResolution;
    const windSpeedChartResolution = this.chartSelectedResolutionMap.find(
      x => x.chartName === ChartNameEnum.windSpeedCanvas,
    ).selectedResolution;
    const uviChartResolution = this.chartSelectedResolutionMap.find(
      x => x.chartName === ChartNameEnum.uviCanvas,
    ).selectedResolution;
    // const soilConductivityChartResolution = this.chartSelectedResolutionMap.find((x) => x.chartName === ChartNameEnum.soilConductivityCanvas).selectedResolution;

    forkJoin([
      this.gatewayDataService.getGatewayTemperature(
        this.selectedSensorDataLocation.id,
        intervalStart,
        intervalEnd,
        temperatureChartResolution,
      ),
      this.gatewayDataService.getGatewaySoilTemperature(
        this.selectedSensorDataLocation.id,
        intervalStart,
        intervalEnd,
        soilTemperatureChartResolution,
      ),
      this.gatewayDataService.getGatewayWaterAmount(
        this.selectedSensorDataLocation.id,
        intervalStart,
        intervalEnd,
        waterAmountChartResolution,
      ),
      this.gatewayDataService.getGatewaySoilMoisture(
        this.selectedSensorDataLocation.id,
        intervalStart,
        intervalEnd,
        soilMoistureChartResolution,
      ),
      this.gatewayDataService.getGatewayCumulativeWaterAmount(
        this.selectedSensorDataLocation.id,
        intervalStart,
        intervalEnd,
        cumulativeWaterAmountChartResolution,
      ),
      this.gatewayDataService.getGatewayHumidity(
        this.selectedSensorDataLocation.id,
        intervalStart,
        intervalEnd,
        humidityChartResolution,
      ),
      this.gatewayDataService.getGatewayPressure(
        this.selectedSensorDataLocation.id,
        intervalStart,
        intervalEnd,
        pressureChartResolution,
      ),
      this.gatewayDataService.getGatewayWindSpeed(
        this.selectedSensorDataLocation.id,
        intervalStart,
        intervalEnd,
        windSpeedChartResolution,
      ),
      this.gatewayDataService.getGatewayUvi(
        this.selectedSensorDataLocation.id,
        intervalStart,
        intervalEnd,
        uviChartResolution,
      ),
      // this.gatewayDataService.getGatewaySoilConductivity(
      //   this.selectedSensorDevice.id,
      //   intervalStart,
      //   intervalEnd,
      //   soilConductivityChartResolution
      // ),
    ]).subscribe(
      ([
        gatewayTemperature,
        gatewaySoilTemperature,
        gatewayWaterAmount,
        gatewaySoilMoisture,
        gatewayCumulativeWaterAmount,
        gatewayHumidity,
        gatewayPressure,
        gatewayWindSpeed,
        gatewayUvi,
        // gatewaySoilConductivity,
      ]) => {
        this.gatewayTemperatureData = gatewayTemperature;
        this.soilTemperatureData = gatewaySoilTemperature;
        this.gatewayWaterAmountData = gatewayWaterAmount;
        this.gatewayCumulativeWaterAmountData = gatewayCumulativeWaterAmount;
        this.soilMoistureData = gatewaySoilMoisture;
        this.gatewayHumidityData = gatewayHumidity;
        this.gatewayPressureData = gatewayPressure;
        this.gatewayWindSpeedData = gatewayWindSpeed;
        this.gatewayUviData = gatewayUvi;

        this.updateTemperatureChart(temperatureChartResolution);
        this.updateSoilTemperatureChart(soilTemperatureChartResolution);
        this.updateWaterAmountChart(waterAmountChartResolution);
        this.updateSoilMoistureChart(soilMoistureChartResolution);
        this.updateCumulativeWaterAmountChart(cumulativeWaterAmountChartResolution);
        this.updateHumidityChart(humidityChartResolution);
        this.updatePressureChart(pressureChartResolution);
        this.updateWindSpeedChart(windSpeedChartResolution);
        this.updateUviChart(uviChartResolution);
        // this.updateSoilConductivityChart(soilConductivityChartResolution);
      },
    );
  }

  private getNodeData(intervalStart: Date, intervalEnd: Date): void {
    const soilTemperatureChartResolution = this.chartSelectedResolutionMap.find(
      x => x.chartName === ChartNameEnum.soilTemperatureCanvas,
    ).selectedResolution;
    const soilMoistureChartResolution = this.chartSelectedResolutionMap.find(
      x => x.chartName === ChartNameEnum.soilMoistureCanvas,
    ).selectedResolution;
    // const soilConductivityChartResolution = this.chartSelectedResolutionMap.find((x) => x.chartName === ChartNameEnum.soilConductivityCanvas).selectedResolution;

    forkJoin([
      this.nodeDataService.getNodeSoilTemperature(
        this.selectedSensorDataLocation.id,
        intervalStart,
        intervalEnd,
        soilTemperatureChartResolution,
      ),
      this.nodeDataService.getNodeSoilMoisture(
        this.selectedSensorDataLocation.id,
        intervalStart,
        intervalEnd,
        soilMoistureChartResolution,
      ),
      // this.nodeDataService.getNodeSoilConductivity(
      //   this.selectedSensorDevice.id,
      //   intervalStart,
      //   intervalEnd,
      //   soilConductivityChartResolution
      // ),
    ]).subscribe(
      ([
        nodeSoilTemperature,
        nodeSoilMoisture,
        // nodeSoilConductivity
      ]) => {
        this.soilTemperatureData = nodeSoilTemperature;
        this.soilMoistureData = nodeSoilMoisture;

        this.updateSoilTemperatureChart(soilTemperatureChartResolution);
        this.updateSoilMoistureChart(soilMoistureChartResolution);
        // this.updateSoilConductivityChart(soilConductivityChartResolution);
      },
    );
  }

  private clearTemperatureChart(): void {
    if (this.temperatureChart === undefined) {
      return;
    }

    this.temperatureChart.data.labels = [];
    this.temperatureChart.data.datasets = [];

    this.temperatureChart.update();
  }

  private updateTemperatureChart(sensorDataResolutionEnum: SensorDataResolutionEnum) {
    const newLabels = [];
    const newDatasets = [];

    if (sensorDataResolutionEnum === SensorDataResolutionEnum.hourly) {
      this.gatewayTemperatureData.hourly.time.forEach(time => {
        newLabels.push(time.toLocaleString());
      });

      const averageDataset = {
        label: this.translateService.instant('dashboard-page.sensor-viewer-panel.average'),
        data: [],
        borderColor: 'rgba(255, 128, 0, 0.8)',
        backgroundColor: 'rgba(255, 128, 0, 0.6)',
      };
      this.gatewayTemperatureData.hourly.average.forEach(average => {
        averageDataset.data.push(average);
      });
      newDatasets.push(averageDataset);
    } else if (sensorDataResolutionEnum === SensorDataResolutionEnum.daily) {
      this.gatewayTemperatureData.daily.time.forEach(time => {
        newLabels.push(time.toLocaleDateString());
      });

      const minimumDataset = {
        label: this.translateService.instant('dashboard-page.sensor-viewer-panel.minimum'),
        data: [],
        borderColor: 'rgba(0, 128, 255, 0.8)',
        backgroundColor: 'rgba(0, 128, 255, 0.6)',
      };
      this.gatewayTemperatureData.daily.minimum.forEach(minimum => {
        minimumDataset.data.push(minimum);
      });
      newDatasets.push(minimumDataset);

      const averageDataset = {
        label: this.translateService.instant('dashboard-page.sensor-viewer-panel.average'),
        data: [],
        borderColor: 'rgba(255, 128, 0, 0.8)',
        backgroundColor: 'rgba(255, 128, 0, 0.6)',
      };
      this.gatewayTemperatureData.daily.average.forEach(average => {
        averageDataset.data.push(average);
      });
      newDatasets.push(averageDataset);

      const maximumDataset = {
        label: this.translateService.instant('dashboard-page.sensor-viewer-panel.maximum'),
        data: [],
        borderColor: 'rgba(255, 0, 0, 0.8)',
        backgroundColor: 'rgba(255, 0, 0, 0.6)',
      };
      this.gatewayTemperatureData.daily.maximum.forEach(maximum => {
        maximumDataset.data.push(maximum);
      });
      newDatasets.push(maximumDataset);
    }

    newLabels.forEach(label => {
      this.temperatureChart.data.labels.push(label);
    });
    newDatasets.forEach(dataset => {
      this.temperatureChart.data.datasets.push(dataset);
    });

    this.temperatureChart.update();
  }

  private clearWaterAmountChart(): void {
    if (this.waterAmountChart === undefined) {
      return;
    }

    this.waterAmountChart.data.labels = [];
    this.waterAmountChart.data.datasets = [];

    this.waterAmountChart.update();
  }

  private updateWaterAmountChart(sensorDataResolutionEnum: SensorDataResolutionEnum) {
    const newLabels = [];
    const newDatasets = [];

    if (sensorDataResolutionEnum === SensorDataResolutionEnum.raw) {
      this.gatewayWaterAmountData.raw.time.forEach(time => {
        newLabels.push(time.toLocaleString());
      });

      const valuesDataset = {
        label: this.translateService.instant('dashboard-page.sensor-viewer-panel.value'),
        data: [],
        borderColor: 'rgba(0, 128, 255, 0.8)',
        backgroundColor: 'rgba(0, 128, 255, 0.6)',
      };
      this.gatewayWaterAmountData.raw.values.forEach(value => {
        valuesDataset.data.push(value);
      });
      newDatasets.push(valuesDataset);
    } else if (sensorDataResolutionEnum === SensorDataResolutionEnum.hourly) {
      this.gatewayWaterAmountData.hourly.time.forEach(time => {
        newLabels.push(time.toLocaleString());
      });

      const sumDataset = {
        label: this.translateService.instant('dashboard-page.sensor-viewer-panel.sum'),
        data: [],
        borderColor: 'rgba(0, 128, 255, 0.8)',
        backgroundColor: 'rgba(0, 128, 255, 0.6)',
      };
      this.gatewayWaterAmountData.hourly.sum.forEach(sum => {
        sumDataset.data.push(sum);
      });
      newDatasets.push(sumDataset);
    } else if (sensorDataResolutionEnum === SensorDataResolutionEnum.daily) {
      this.gatewayWaterAmountData.daily.time.forEach(time => {
        newLabels.push(time.toLocaleDateString());
      });

      const sumDataset = {
        label: this.translateService.instant('dashboard-page.sensor-viewer-panel.sum'),
        data: [],
        borderColor: 'rgba(0, 128, 255, 0.8)',
        backgroundColor: 'rgba(0, 128, 255, 0.6)',
      };
      this.gatewayWaterAmountData.daily.sum.forEach(sum => {
        sumDataset.data.push(sum);
      });
      newDatasets.push(sumDataset);
    }

    newLabels.forEach(label => {
      this.waterAmountChart.data.labels.push(label);
    });
    newDatasets.forEach(dataset => {
      this.waterAmountChart.data.datasets.push(dataset);
    });

    this.waterAmountChart.update();
  }

  private clearCumulativeWaterAmountChart(): void {
    if (this.cumulativeWaterAmountChart === undefined) {
      return;
    }

    this.cumulativeWaterAmountChart.data.labels = [];
    this.cumulativeWaterAmountChart.data.datasets = [];

    this.cumulativeWaterAmountChart.update();
  }

  private updateCumulativeWaterAmountChart(sensorDataResolutionEnum: SensorDataResolutionEnum) {
    const newLabels = [];
    const newDatasets = [];

    if (sensorDataResolutionEnum === SensorDataResolutionEnum.hourly) {
      this.gatewayCumulativeWaterAmountData.hourly.time.forEach(time => {
        newLabels.push(time.toLocaleString());
      });

      const sumDataset = {
        label: this.translateService.instant('dashboard-page.sensor-viewer-panel.sum'),
        data: [],
        borderColor: 'rgba(0, 128, 255, 0.8)',
        backgroundColor: 'rgba(0, 128, 255, 0.6)',
        fill: true,
      };
      this.gatewayCumulativeWaterAmountData.hourly.sum.forEach(sum => {
        sumDataset.data.push(sum);
      });
      newDatasets.push(sumDataset);
    } else if (sensorDataResolutionEnum === SensorDataResolutionEnum.daily) {
      this.gatewayCumulativeWaterAmountData.daily.time.forEach(time => {
        newLabels.push(time.toLocaleDateString());
      });

      const sumDataset = {
        label: this.translateService.instant('dashboard-page.sensor-viewer-panel.sum'),
        data: [],
        borderColor: 'rgba(0, 128, 255, 0.8)',
        backgroundColor: 'rgba(0, 128, 255, 0.6)',
        fill: true,
      };
      this.gatewayCumulativeWaterAmountData.daily.sum.forEach(sum => {
        sumDataset.data.push(sum);
      });
      newDatasets.push(sumDataset);
    }

    newLabels.forEach(label => {
      this.cumulativeWaterAmountChart.data.labels.push(label);
    });
    newDatasets.forEach(dataset => {
      this.cumulativeWaterAmountChart.data.datasets.push(dataset);
    });

    this.cumulativeWaterAmountChart.update();
  }

  private clearHumidityChart(): void {
    if (this.humidityChart === undefined) {
      return;
    }

    this.humidityChart.data.labels = [];
    this.humidityChart.data.datasets = [];

    this.humidityChart.update();
  }

  private updateHumidityChart(sensorDataResolutionEnum: SensorDataResolutionEnum) {
    const newLabels = [];
    const newDatasets = [];

    if (sensorDataResolutionEnum === SensorDataResolutionEnum.hourly) {
      this.gatewayHumidityData.hourly.time.forEach(time => {
        newLabels.push(time.toLocaleString());
      });

      const averageDataset = {
        label: this.translateService.instant('dashboard-page.sensor-viewer-panel.average'),
        data: [],
        borderColor: 'rgba(255, 128, 0, 0.8)',
        backgroundColor: 'rgba(255, 128, 0, 0.6)',
      };
      this.gatewayHumidityData.hourly.average.forEach(average => {
        averageDataset.data.push(average);
      });
      newDatasets.push(averageDataset);
    } else if (sensorDataResolutionEnum === SensorDataResolutionEnum.daily) {
      this.gatewayHumidityData.daily.time.forEach(time => {
        newLabels.push(time.toLocaleDateString());
      });

      const averageDataset = {
        label: this.translateService.instant('dashboard-page.sensor-viewer-panel.average'),
        data: [],
        borderColor: 'rgba(255, 128, 0, 0.8)',
        backgroundColor: 'rgba(255, 128, 0, 0.6)',
      };
      this.gatewayHumidityData.daily.average.forEach(average => {
        averageDataset.data.push(average);
      });
      newDatasets.push(averageDataset);
    }

    newLabels.forEach(label => {
      this.humidityChart.data.labels.push(label);
    });
    newDatasets.forEach(dataset => {
      this.humidityChart.data.datasets.push(dataset);
    });

    this.humidityChart.update();
  }

  private clearPressureChart(): void {
    if (this.pressureChart === undefined) {
      return;
    }

    this.pressureChart.data.labels = [];
    this.pressureChart.data.datasets = [];

    this.pressureChart.update();
  }

  private updatePressureChart(sensorDataResolutionEnum: SensorDataResolutionEnum) {
    const newLabels = [];
    const newDatasets = [];

    if (sensorDataResolutionEnum === SensorDataResolutionEnum.raw) {
      this.gatewayPressureData.raw.time.forEach(time => {
        newLabels.push(time.toLocaleString());
      });

      const valuesDataset = {
        label: this.translateService.instant('dashboard-page.sensor-viewer-panel.value'),
        data: [],
        borderColor: 'rgba(255, 128, 0, 0.8)',
        backgroundColor: 'rgba(255, 128, 0, 0.6)',
      };
      this.gatewayPressureData.raw.values.forEach(value => {
        valuesDataset.data.push(value);
      });
      newDatasets.push(valuesDataset);
    } else if (sensorDataResolutionEnum === SensorDataResolutionEnum.hourly) {
      this.gatewayPressureData.hourly.time.forEach(time => {
        newLabels.push(time.toLocaleString());
      });

      const averageDataset = {
        label: this.translateService.instant('dashboard-page.sensor-viewer-panel.average'),
        data: [],
        borderColor: 'rgba(255, 128, 0, 0.8)',
        backgroundColor: 'rgba(255, 128, 0, 0.6)',
      };
      this.gatewayPressureData.hourly.average.forEach(average => {
        averageDataset.data.push(average);
      });
      newDatasets.push(averageDataset);
    }

    newLabels.forEach(label => {
      this.pressureChart.data.labels.push(label);
    });
    newDatasets.forEach(dataset => {
      this.pressureChart.data.datasets.push(dataset);
    });

    this.pressureChart.update();
  }

  private clearWindSpeedChart(): void {
    if (this.windSpeedChart === undefined) {
      return;
    }

    this.windSpeedChart.data.labels = [];
    this.windSpeedChart.data.datasets = [];

    this.windSpeedChart.update();
  }

  private updateWindSpeedChart(sensorDataResolutionEnum: SensorDataResolutionEnum) {
    const newLabels = [];
    const newDatasets = [];

    if (sensorDataResolutionEnum === SensorDataResolutionEnum.raw) {
      this.gatewayWindSpeedData.raw.time.forEach(time => {
        newLabels.push(time.toLocaleString());
      });

      const valuesDataset = {
        label: this.translateService.instant('dashboard-page.sensor-viewer-panel.value'),
        data: [],
        borderColor: 'rgba(255, 128, 0, 0.8)',
        backgroundColor: 'rgba(255, 128, 0, 0.6)',
      };
      this.gatewayWindSpeedData.raw.values.forEach(value => {
        valuesDataset.data.push(value);
      });
      newDatasets.push(valuesDataset);
    } else if (sensorDataResolutionEnum === SensorDataResolutionEnum.hourly) {
      this.gatewayWindSpeedData.hourly.time.forEach(time => {
        newLabels.push(time.toLocaleString());
      });

      const averageDataset = {
        label: this.translateService.instant('dashboard-page.sensor-viewer-panel.average'),
        data: [],
        borderColor: 'rgba(255, 128, 0, 0.8)',
        backgroundColor: 'rgba(255, 128, 0, 0.6)',
      };
      this.gatewayWindSpeedData.hourly.average.forEach(average => {
        averageDataset.data.push(average);
      });
      newDatasets.push(averageDataset);
    }

    newLabels.forEach(label => {
      this.windSpeedChart.data.labels.push(label);
    });
    newDatasets.forEach(dataset => {
      this.windSpeedChart.data.datasets.push(dataset);
    });

    this.windSpeedChart.update();
  }

  private clearUviChart(): void {
    if (this.uviChart === undefined) {
      return;
    }

    this.uviChart.data.labels = [];
    this.uviChart.data.datasets = [];

    this.uviChart.update();
  }

  private updateUviChart(sensorDataResolutionEnum: SensorDataResolutionEnum) {
    const newLabels = [];
    const newDatasets = [];

    if (sensorDataResolutionEnum === SensorDataResolutionEnum.hourly) {
      this.gatewayUviData.hourly.time.forEach(time => {
        newLabels.push(time.toLocaleString());
      });

      const averageDataset = {
        label: this.translateService.instant('dashboard-page.sensor-viewer-panel.average'),
        data: [],
        borderColor: 'rgba(255, 128, 0, 0.8)',
        backgroundColor: 'rgba(255, 128, 0, 0.6)',
      };
      this.gatewayUviData.hourly.average.forEach(average => {
        averageDataset.data.push(average);
      });
      newDatasets.push(averageDataset);
    }

    newLabels.forEach(label => {
      this.uviChart.data.labels.push(label);
    });
    newDatasets.forEach(dataset => {
      this.uviChart.data.datasets.push(dataset);
    });

    this.uviChart.update();
  }

  private clearSoilTemperatureChart(): void {
    if (this.soilTemperatureChart === undefined) {
      return;
    }

    this.soilTemperatureChart.data.labels = [];
    this.soilTemperatureChart.data.datasets = [];

    this.soilTemperatureChart.update();
  }

  private updateSoilTemperatureChart(sensorDataResolutionEnum: SensorDataResolutionEnum) {
    const newLabels = [];
    const newDatasets = [];

    if (sensorDataResolutionEnum === SensorDataResolutionEnum.hourly) {
      this.soilTemperatureData.hourly.time.forEach(time => {
        newLabels.push(time.toLocaleString());
      });

      const averageDataset = {
        label: this.translateService.instant('dashboard-page.sensor-viewer-panel.average'),
        data: [],
        borderColor: 'rgba(255, 128, 0, 0.8)',
        backgroundColor: 'rgba(255, 128, 0, 0.6)',
      };
      this.soilTemperatureData.hourly.average.forEach(average => {
        averageDataset.data.push(average);
      });
      newDatasets.push(averageDataset);
    } else if (sensorDataResolutionEnum === SensorDataResolutionEnum.daily) {
      this.soilTemperatureData.daily.time.forEach(time => {
        newLabels.push(time.toLocaleDateString());
      });

      const minimumDataset = {
        label: this.translateService.instant('dashboard-page.sensor-viewer-panel.minimum'),
        data: [],
        borderColor: 'rgba(0, 128, 255, 0.8)',
        backgroundColor: 'rgba(0, 128, 255, 0.6)',
      };
      this.soilTemperatureData.daily.minimum.forEach(minimum => {
        minimumDataset.data.push(minimum);
      });
      newDatasets.push(minimumDataset);

      const averageDataset = {
        label: this.translateService.instant('dashboard-page.sensor-viewer-panel.average'),
        data: [],
        borderColor: 'rgba(255, 128, 0, 0.8)',
        backgroundColor: 'rgba(255, 128, 0, 0.6)',
      };
      this.soilTemperatureData.daily.average.forEach(average => {
        averageDataset.data.push(average);
      });
      newDatasets.push(averageDataset);

      const maximumDataset = {
        label: this.translateService.instant('dashboard-page.sensor-viewer-panel.maximum'),
        data: [],
        borderColor: 'rgba(255, 0, 0, 0.8)',
        backgroundColor: 'rgba(255, 0, 0, 0.6)',
      };
      this.soilTemperatureData.daily.maximum.forEach(maximum => {
        maximumDataset.data.push(maximum);
      });
      newDatasets.push(maximumDataset);
    }

    newLabels.forEach(label => {
      this.soilTemperatureChart.data.labels.push(label);
    });
    newDatasets.forEach(dataset => {
      this.soilTemperatureChart.data.datasets.push(dataset);
    });

    this.soilTemperatureChart.update();
  }

  private clearSoilMoistureChart(): void {
    if (this.soilMoistureChart === undefined) {
      return;
    }

    this.soilMoistureChart.data.labels = [];
    this.soilMoistureChart.data.datasets = [];

    this.soilMoistureChart.update();
  }

  private updateSoilMoistureChart(sensorDataResolutionEnum: SensorDataResolutionEnum) {
    const newLabels = [];
    const newDatasets = [];

    if (sensorDataResolutionEnum === SensorDataResolutionEnum.raw) {
      this.soilMoistureData.raw.time.forEach(time => {
        newLabels.push(time.toLocaleString());
      });

      const valuesDataset = {
        label: this.translateService.instant('dashboard-page.sensor-viewer-panel.value'),
        data: [],
        borderColor: 'rgba(0, 128, 255, 0.8)',
        backgroundColor: 'rgba(0, 128, 255, 0.6)',
      };
      this.soilMoistureData.raw.values.forEach(value => {
        valuesDataset.data.push(value);
      });
      newDatasets.push(valuesDataset);
    } else if (sensorDataResolutionEnum === SensorDataResolutionEnum.hourly) {
      this.soilMoistureData.hourly.time.forEach(time => {
        newLabels.push(time.toLocaleString());
      });

      const averageDataset = {
        label: this.translateService.instant('dashboard-page.sensor-viewer-panel.average'),
        data: [],
        borderColor: 'rgba(255, 128, 0, 0.8)',
        backgroundColor: 'rgba(255, 128, 0, 0.6)',
      };
      this.soilMoistureData.hourly.average.forEach(average => {
        averageDataset.data.push(average);
      });
      newDatasets.push(averageDataset);
    } else if (sensorDataResolutionEnum === SensorDataResolutionEnum.daily) {
      this.soilMoistureData.daily.time.forEach(time => {
        newLabels.push(time.toLocaleDateString());
      });

      const minimumDataset = {
        label: this.translateService.instant('dashboard-page.sensor-viewer-panel.minimum'),
        data: [],
        borderColor: 'rgba(0, 128, 255, 0.8)',
        backgroundColor: 'rgba(0, 128, 255, 0.6)',
      };
      this.soilMoistureData.daily.minimum.forEach(minimum => {
        minimumDataset.data.push(minimum);
      });
      newDatasets.push(minimumDataset);

      const averageDataset = {
        label: this.translateService.instant('dashboard-page.sensor-viewer-panel.average'),
        data: [],
        borderColor: 'rgba(255, 128, 0, 0.8)',
        backgroundColor: 'rgba(255, 128, 0, 0.6)',
      };
      this.soilMoistureData.daily.average.forEach(average => {
        averageDataset.data.push(average);
      });
      newDatasets.push(averageDataset);

      const maximumDataset = {
        label: this.translateService.instant('dashboard-page.sensor-viewer-panel.maximum'),
        data: [],
        borderColor: 'rgba(255, 0, 0, 0.8)',
        backgroundColor: 'rgba(255, 0, 0, 0.6)',
      };
      this.soilMoistureData.daily.maximum.forEach(maximum => {
        maximumDataset.data.push(maximum);
      });
      newDatasets.push(maximumDataset);
    }

    newLabels.forEach(label => {
      this.soilMoistureChart.data.labels.push(label);
    });
    newDatasets.forEach(dataset => {
      this.soilMoistureChart.data.datasets.push(dataset);
    });

    this.soilMoistureChart.update();
  }

  private clearAllCharts(): void {
    this.clearTemperatureChart();
    this.clearWaterAmountChart();
    this.clearCumulativeWaterAmountChart();
    this.clearHumidityChart();
    this.clearPressureChart();
    this.clearWindSpeedChart();
    this.clearUviChart();
    this.clearSoilTemperatureChart();
    this.clearSoilMoistureChart();
  }

  // private updateSoilConductivityChart(sensorDataResolutionEnum: SensorResolutionEnum) {
  //   const newLabels = this.getSensorDataChartLabels();

  //   const newDatasets = [
  //     {
  //       label: this.translateService.instant(
  //         'dashboard-page.sensor-viewer-panel.average'
  //       ),
  //       data: this.sensorData.map((data) => data.soilConductivity),
  //       borderColor: 'rgba(255, 128, 0, 0.8)',
  //       backgroundColor: 'rgba(255, 128, 0, 0.6)',
  //     },
  //   ];

  //   this.soilConductivityChart.data.labels = [];
  //   this.soilConductivityChart.data.datasets = [];

  //   newLabels.forEach((label) => {
  //     this.soilConductivityChart.data.labels.push(label);
  //   });
  //   newDatasets.forEach((dataset) => {
  //     this.soilConductivityChart.data.datasets.push(dataset);
  //   });

  //   this.soilConductivityChart.update();
  // }
}
