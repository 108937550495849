<dfarm-panel
  class="parent-panel"
  [isVisible]="panelIsOpen"
  [isLeftSide]="false"
  [activePane]="activePane"
  [isSlideable]="true"
  [isClosable]="true"
  [isMultiViewPanel]="true"
  *ngIf="!isLoading"
>
  <div pane1 class="child-panel">
    <div class="title text-xl">
      {{ 'dashboard-page.field-inspector-panel.fields-overview' | translate }}
    </div>
    <div class="p-4 pt-0 h-[calc(100%-2*1rem-28px)] w-full">
      <div class="input-wrapper">
        <div class="search-icon">
          <img
            class="h-9"
            src="../../../../../assets/field-inspection-icons/search.svg"
            alt="search"
          />
        </div>
        <input
          class="text-base"
          name="search"
          type="text"
          [formControl]="searchField"
        />
      </div>
      <div
        class="field-list"
        *ngIf="fields.length > 0 && filteredFields.length > 0"
      >
        <div
          class="field-list-item"
          (click)="selectField(field)"
          *ngFor="let field of filteredFields"
        >
          <div class="field-list-item-title text-lg">{{ field.name }}</div>
          <div class="icons flex gap-2">
            <dfarm-button (click)="editFieldName(field.id, field.name); $event.stopPropagation()">
              <img
                src="../../../../../assets/field-inspection-icons/edit.svg"
                alt="edit"
              />
            </dfarm-button>
            <dfarm-button (click)="deleteField(field.id); $event.stopPropagation()">
              <img
                src="../../../../../assets/field-inspection-icons/trash.svg"
                alt="delete"
              />
            </dfarm-button>
          </div>
        </div>
      </div>
      <div class="field-list" *ngIf="fields.length === 0">
        <div
          class="flex flex-col justify-center items-center gap-1 h-full text-white"
        >
          <div>
            {{ 'dashboard-page.field-inspector-panel.no-fields' | translate }}
          </div>
          <div>
            {{
              'dashboard-page.field-inspector-panel.please-add-new-field'
                | translate
            }}
          </div>
          <dfarm-button
            (click)="showAddFieldPanel()"
            [lightGreenButton]="true">
            {{ 'dashboard-page.field-inspector-panel.add-new-field' | translate }}
          </dfarm-button>
        </div>
      </div>
      <div class="field-list" *ngIf="filteredFields.length === 0">
        <div
          class="flex flex-col justify-center items-center gap-1 h-full text-white"
        >
          <div>
            {{
              'dashboard-page.field-inspector-panel.no-fields-found' | translate
            }}
          </div>
        </div>
      </div>
    </div>
  </div>

  <div pane2 class="child-panel">
    <img
      class="back-icon ml-4"
      src="../../../../../assets/field-inspection-icons/arrow.svg"
      (click)="backToList()"
      alt="back"
    />
    <!-- <div class="title text-xl">Tábla adatok</div> -->
    <div class="title text-xl">{{ selectedField?.name }}</div>

    <div class="p-4 pt-0 h-[calc(100%-2*1rem-28px)] w-full">
      <!-- <mat-tab-group>
        <mat-tab label="Jelentések">
          <dfarm-report
            [landId]="selectedField?.id"
            (selectSatelliteImagesOutput)="
              forwardSelectedSatelliteImages($event)
            "
          ></dfarm-report>
        </mat-tab>
        <mat-tab label="Parcella adatok">
          <dfarm-parcell-details></dfarm-parcell-details>
        </mat-tab>
        <mat-tab label="Termesztési ciklus">
          <dfarm-growing-cycle></dfarm-growing-cycle>
        </mat-tab>
        <mat-tab label="Áttekintés">
          <dfarm-field-overview
            [selectedField]="selectedField"
          ></dfarm-field-overview>
        </mat-tab>
      </mat-tab-group> -->
      <dfarm-field-overview
        [selectedField]="selectedField"
      ></dfarm-field-overview>
    </div>
  </div>
</dfarm-panel>
