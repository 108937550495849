<dfarm-panel
  [isVisible]="panelIsOpen"
  [isLeftSide]="false"
  [wrapperIsBlock]="false"
  [isClosable]="true"
  [isOverflowScrollablePanel]="true"
  *ngIf="!isLoading && hasPermission">
  <div class="title text-xl">
    {{ 'dashboard-page.data-viewer-panel.weather-data' | translate }}
  </div>
  <!-- Különböző diagramok amik mutatják az időjárási adatokat -->
  <div
    class="p-4 pt-0 h-[calc(100%-2*1rem-28px)] w-full"
    *ngIf="fields.length > 0">
    <div class="flex-col justify-between h-full">
      <div class="flex justify-between items-center mb-4">
        <dfarm-button
          (click)="selectPreviousField()">
          <img
            class="h-7"
            src="../../../../../assets/quick-viewer-icons/navi-left.svg" alt="left-arrow" height="38" width="15"/>
        </dfarm-button>
        <div
          class="text-xl text-center text-white"
          *ngIf="selectedField !== undefined">
          {{ selectedField.name }}
        </div>
        <dfarm-button
          (click)="selectNextField()">
          <img
            class="h-7"
            src="../../../../../assets/quick-viewer-icons/navi-right.svg" alt="right-arrow" height="38" width="15"/>
        </dfarm-button>
      </div>
      <mat-tab-group>
        <mat-tab
          [label]="
            getTranslationTitle('dashboard-page.data-viewer-panel.daily')
          ">
          <div class="w-full h-full">
            <dfarm-field-data-overview></dfarm-field-data-overview>
          </div>
        </mat-tab>
        <mat-tab
          [label]="
            getTranslationTitle('dashboard-page.data-viewer-panel.details')
          ">
          <div class="w-full h-full">
            <dfarm-field-data-details></dfarm-field-data-details>
          </div>
        </mat-tab>
      </mat-tab-group>
    </div>
  </div>

  <!-- Ez a rész akkor jelenik meg, ha egyetlen földje sincs a felhasznólnak -->
  <div class="field-list" *ngIf="fields.length === 0">
    <div
      class="flex flex-col justify-center items-center gap-1 h-full text-white">
      <div>{{ 'dashboard-page.data-viewer-panel.no-fields' | translate }}</div>
      <div>
        {{ 'dashboard-page.data-viewer-panel.add-new-field' | translate }}
      </div>
      <dfarm-button
        (click)="showAddFieldPanel()"
        [lightGreenButton]="true">
        {{ 'dashboard-page.data-viewer-panel.add-new-field-button' | translate }}
      </dfarm-button>
    </div>
  </div>
</dfarm-panel>
