import {
  animate,
  state,
  style,
  transition,
  trigger,
} from '@angular/animations';
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
} from '@angular/core';
import { Store } from '@ngrx/store';
import { CorePartialState } from 'src/app/core/state/core.reducer';
import { DashboardPartialState } from 'src/app/pages/dashboard/state/dashboard.reducer';
import * as CoreActions from '../../../core/state/core.actions';
import * as DashboardActions from '../../../pages/dashboard/state/dashboard.actions';
import { dfarmDelay } from '../../utils/utils';
import { dFarmAnimationTime } from '../../utils/variables';
import { CommonModule } from '@angular/common';
import { CloseButtonComponent } from '../close-button/close-button.component';

@Component({
  selector: 'dfarm-panel',
  templateUrl: './panel.component.html',
  styleUrls: ['./panel.component.scss'],
  // Slide-hoz kell
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [
    trigger('slide', [
      state('pane1', style({ transform: 'translateX(0)' })),
      state('pane2', style({ transform: 'translateX(-25%)' })),
      state('pane3', style({ transform: 'translateX(-50%)' })),
      state('pane4', style({ transform: 'translateX(-75%)' })),
      transition('* => *', animate('600ms ease')),
    ]),
  ],
  standalone: true,
  imports: [CommonModule, CloseButtonComponent],
})
export class PanelComponent implements OnInit, OnChanges, AfterViewInit {
  @Input() isVisible = false;
  @Input() isLeftSide = false;
  @Input() isClosable = false;
  @Input() wrapperIsBlock = false;
  @Input() isWidePanel = false;
  // Becsúszáshoz kell
  @Input() isSlideable = false;
  @Input() activePane = 'pane1';
  // Mobil nézeten oldalra van-e csúszva
  @Input() isRightSlideOver = false;
  // Mobil nézeten GPS felvételnél le van-e csúszva
  @Input() isSlideDown = false;
  // Ez az a panel, ami mobilon félig van fent
  @Input() isMultiViewPanel = false;
  // Ez az a panel, ami alulról felcsúszik teljes képernyőig és lehet scrollozni
  @Input() isOverflowScrollablePanel = false;
  // Ez az a panel, ami mobilon csak 40%-ig jön fel (például föld felvétel)
  @Input() isSmallPanel = false;

  constructor(
    private readonly coreStore: Store<CorePartialState>,
    private readonly dashboardStore: Store<DashboardPartialState>,
  ) {}

  ngOnInit(): void {
    this.addRelativeClassToOverflowPanel();
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.addRelativeClassToOverflowPanel();
  }

  ngAfterViewInit(): void {
    this.addRelativeClassToOverflowPanel();
  }

  async closePanel(): Promise<void> {
    // Itt gyűjtjük a paneleket, amiket be kell zárni.
    // Ha új panelt hozunk létre, akkor ide hozzá kell adni.
    this.coreStore.dispatch(CoreActions.closeAddFieldPanel());
    this.coreStore.dispatch(CoreActions.closeFieldInspectorPanel());
    this.coreStore.dispatch(CoreActions.closeDfarmServicesPanel());
    this.coreStore.dispatch(CoreActions.closeInformationPanel());
    this.coreStore.dispatch(CoreActions.closeDataViewerPanel());
    this.coreStore.dispatch(CoreActions.closeSatelliteViewerPanel());
    this.coreStore.dispatch(CoreActions.closeSensorViewerPanel());
    this.coreStore.dispatch(CoreActions.closeWaterscopeItemListPanel());
    this.coreStore.dispatch(CoreActions.closeWaterscopeTablePanel());
    this.coreStore.dispatch(CoreActions.closeWaterscopeChartPanel());
    await dfarmDelay(dFarmAnimationTime);
    // Itt történnek azok a dolgok, amik a bezárási animáció után kell, hogy történejenek
    this.dashboardStore.dispatch(
      DashboardActions.changeAddFieldPane({ activePane: 'pane1' }),
    );
    this.dashboardStore.dispatch(
      DashboardActions.unselectDrawingOnMapFieldDesignation(),
    );
    this.dashboardStore.dispatch(
      DashboardActions.unselectGPSFieldDesignation(),
    );
    this.dashboardStore.dispatch(DashboardActions.clearAddFieldPanelData());
  }

  private async addRelativeClassToOverflowPanel(): Promise<void> {
    const panel = document.getElementsByClassName('overflow-scrollable-panel');
    if (this.isOverflowScrollablePanel) {
      for (let i = 0; i < panel.length; i++) {
        if (this.isVisible) {
          await dfarmDelay(dFarmAnimationTime);
          panel[i].classList.add('overflow-relative');
        } else if (!this.isVisible) {
          panel[i].classList.remove('overflow-relative');
        }
      }
    }
  }
}
