<div class="text-2xl">{{ data }}</div>
<div class="flex justify-between pt-6">
  <dfarm-button
    (click)="cancel()"
    [darkGreenButton]="true">
    {{ 'dialogs.confirm-dialog.no' | translate }}
  </dfarm-button>
  <dfarm-button
    (click)="accept()"
    [lightGreenButton]="true">
    {{ 'dialogs.confirm-dialog.yes' | translate }}
  </dfarm-button>
</div>
