<div class="flex flex-col items-center h-fit text-center text-white w-full p-2">
  <form
    [formGroup]="dateRangeSelectorForm"
    class="w-full text-sm mb-2 flex justify-evenly items-center">
    <div>
      <label>
        <input
          type="date"
          class="bg-transparent w-28 text-center text-sm"
          formControlName="startDate"
          required
          [max]="formControls.endDate.value" />
      </label>
      -
      <label>
        <input
          type="date"
          class="bg-transparent w-28 text-center text-sm"
          formControlName="endDate"
          required
          [max]="maxDate"
          [min]="formControls.startDate.value" />
      </label>
    </div>
  </form>
  <div
    class="h-full w-[90%] flex flex-col padding-2 desktop:w-[95%] mobileVertical:overflow-auto desktop:overflow-auto tabletHorizontal:overflow-auto padding-2 rounded-lg">
    <!-- <div
    class="h-full w-full flex flex-col overflow-auto padding-2 rounded-[10px]"
  > -->
    <div class="relative bg-sunkenBox shadow-sunkenBoxShadow">
      <canvas #airTemperatureCanvas id="airTemperatureCanvas"> </canvas>
    </div>
    <!-- <div class="relative bg-sunkenBox shadow-sunkenBoxShadow">
      <canvas
        #cumulativeAverageAirTemperatureCanvas
        id="cumulativeAverageAirTemperatureCanvas"
      ></canvas>
    </div> -->
    <div class="relative bg-sunkenBox shadow-sunkenBoxShadow">
      <canvas #soilTemperatureT0Canvas id="soilTemperatureT0Canvas"></canvas>
    </div>
    <!-- <div class="relative bg-sunkenBox shadow-sunkenBoxShadow">
      <canvas
        #cumulativeAverageSoilTemperatureT0Canvas
        id="cumulativeAverageSoilTemperatureT0Canvas"
      ></canvas>
    </div> -->
    <div class="relative bg-sunkenBox shadow-sunkenBoxShadow">
      <canvas #soilTemperatureT10Canvas id="soilTemperatureT10Canvas"></canvas>
    </div>
    <!-- <div class="relative bg-sunkenBox shadow-sunkenBoxShadow">
      <canvas
        #cumulativeAverageSoilTemperatureT10Canvas
        id="cumulativeAverageSoilTemperatureT10Canvas"
      ></canvas>
    </div> -->
    <div class="relative bg-sunkenBox shadow-sunkenBoxShadow">
      <canvas #precipitationCanvas id="precipitationCanvas"></canvas>
    </div>
    <div class="relative bg-sunkenBox shadow-sunkenBoxShadow">
      <canvas
        #cumulativePrecipitationCanvas
        id="cumulativePrecipitationCanvas"></canvas>
    </div>
    <div class="relative bg-sunkenBox shadow-sunkenBoxShadow">
      <canvas #humidityCanvas id="humidityCanvas"></canvas>
    </div>
    <div class="relative bg-sunkenBox shadow-sunkenBoxShadow">
      <canvas #uviCanvas id="uviCanvas"></canvas>
    </div>
  </div>
</div>
