<mat-form-field
  class="w-full"
  color="primary"
  [ngClass]="{ '!text-white': !isWhite }">
  <mat-label [ngClass]="{ 'text-white': isWhite }">
    {{ 'navbar.language' | translate }}
  </mat-label>
  <mat-select [formControl]="selectedLanguage">
    <mat-option value="hu">
      <div class="flex gap-1 items-center">
        <img
          ngSrc="assets/flags/hu.svg"
          alt=""
          class="h-6 w-6 object-cover rounded-full" height="600" width="1200"/>
        <div>Magyar</div>
      </div>
    </mat-option>
    <mat-option value="en">
      <div class="flex gap-1 items-center">
        <img
          ngSrc="assets/flags/gb.svg"
          alt=""
          class="h-6 w-6 object-cover rounded-full" height="600" width="1200"/>
        <div>English</div>
      </div>
    </mat-option>
    <!-- <mat-option value="de">
        <div class="flex gap-1 items-center">
          <img
            src="assets/flags/de.svg"
            alt=""
            class="h-6 w-6 object-cover rounded-full"
          />
          <div>Deutsch</div>
        </div>
      </mat-option>
      <mat-option value="sk">
        <div class="flex gap-1 items-center">
          <img
            src="assets/flags/sk.svg"
            alt=""
            class="h-6 w-6 object-cover rounded-full"
          />
          <div>Slovenský</div>
        </div>
      </mat-option> -->
  </mat-select>
</mat-form-field>
