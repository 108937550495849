import { Component, inject, OnInit, signal } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BasePanelComponent } from '../../base-panel.component';
import { PanelComponent } from '../../../../../shared/components/panel/panel.component';
import { select, Store } from '@ngrx/store';
import * as CoreQueries from '../../../../../core/state/core.selectors';
import { CorePartialState } from '../../../../../core/state/core.reducer';
import { WateringDeviceService } from '../../../../../shared/services/watering-device.service';
import { AuthService } from '../../../../login/auth.service';
import { debounceTime } from 'rxjs/operators';
import { ButtonComponent } from '../../../../../shared/components/button/button.component';
import { FormControl, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { WateringDeviceData } from '../../../../../shared/models/watering-device.model';
import * as DashboardActions from '../../../state/dashboard.actions';
import { DashboardPartialState } from '../../../state/dashboard.reducer';

@Component({
  selector: 'dfarm-waterscope-item-list',
  standalone: true,
  imports: [
    CommonModule,
    PanelComponent,
    ButtonComponent,
    FormsModule,
    TranslateModule,
    ReactiveFormsModule,
  ],
  templateUrl: './waterscope-item-list.component.html',
  styleUrl: './waterscope-item-list.component.scss',
})
export class WaterscopeItemListComponent
  extends BasePanelComponent
  implements OnInit
{
  wateringDevices: WateringDeviceData[] = [
    {
      id: '1',
      name: 'Watering Device 1',
      brand: 'Brand 1',
      capacity: 100,
      dateOfInstallation: new Date(),
      userId: 'userId',
      dateOfManufacture: new Date(),
    },
    {
      id: '2',
      name: 'Watering Device 2',
      brand: 'Brand 2',
      capacity: 200,
      dateOfInstallation: new Date(),
      userId: 'userId',
      dateOfManufacture: new Date(),
    },
    {
      id: '3',
      name: 'Watering Device 3',
      brand: 'Brand 3',
      capacity: 300,
      dateOfInstallation: new Date(),
      userId: 'userId',
      dateOfManufacture: new Date(),
    },
  ];
  filteredWateringDevices: WateringDeviceData[] = [
    {
      id: '1',
      name: 'Watering Device 1',
      brand: 'Brand 1',
      capacity: 100,
      dateOfInstallation: new Date(),
      userId: 'userId',
      dateOfManufacture: new Date(),
    },
    {
      id: '2',
      name: 'Watering Device 2',
      brand: 'Brand 2',
      capacity: 200,
      dateOfInstallation: new Date(),
      userId: 'userId',
      dateOfManufacture: new Date(),
    },
    {
      id: '3',
      name: 'Watering Device 3',
      brand: 'Brand 3',
      capacity: 300,
      dateOfInstallation: new Date(),
      userId: 'userId',
      dateOfManufacture: new Date(),
    },
  ];
  activePane = 'pane1';
  searchField = new FormControl('');

  selectedWateringDevice = signal(undefined);
  private readonly coreStore = inject(Store<CorePartialState>);
  private readonly wateringDeviceService = inject(WateringDeviceService);
  private readonly authService = inject(AuthService);
  private readonly dashboardStore: Store<DashboardPartialState> = inject(
    Store<DashboardPartialState>,
  );

  constructor() {
    super();
  }

  ngOnInit(): void {
    this.coreStore
      .pipe(select(CoreQueries.getWaterscopeItemListPanelIsOpen))
      .subscribe(panelIsOpen => {
        this.panelIsOpen = panelIsOpen;
        this.isLoading = false;
        if (!this.panelIsOpen) {
          this.activePane = 'pane1';
          this.dashboardStore.dispatch(
            DashboardActions.setSelectedWateringDevice(undefined),
          );
        }
      });
    // this.authService.user
    //   .pipe(
    //     switchMap(user => {
    //       return this.wateringDeviceService.getWateringDevicesByUser(
    //         user.userId,
    //       );
    //     }),
    //   )
    //   .subscribe(wateringDevices => {
    //     console.log('wateringDevices: ', structuredClone(wateringDevices));
    //   });

    this.filterWateringDevices();
    this.isLoading = false;
  }

  selectWateringDevice(wateringDevice: WateringDeviceData): void {
    // this.selectFieldOutput.emit(field);
    // this.selectedField = field;
    this.selectedWateringDevice.set(wateringDevice);
    this.activePane = 'pane2';
    this.dashboardStore.dispatch(
      DashboardActions.setSelectedWateringDevice({ wateringDevice }),
    );
  }

  backToList(): void {
    this.selectedWateringDevice.set(undefined);
    this.dashboardStore.dispatch(
      DashboardActions.setSelectedWateringDevice({ wateringDevice: undefined }),
    );
    this.activePane = 'pane1';
  }

  private filterWateringDevices() {
    this.searchField.valueChanges
      .pipe(debounceTime(500))
      .subscribe(searchInput => {
        this.filteredWateringDevices = this.wateringDevices.filter(field =>
          field.name.toLowerCase().includes(searchInput.toLowerCase()),
        );
      });
  }
}
