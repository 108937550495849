<div
  class="flex flex-col items-center h-full text-center text-white w-full p-2"
>
  <form
    [formGroup]="dateRangeSelectorForm"
    class="w-full text-sm mb-2 flex justify-evenly items-center"
  >
    <div>
      <label>
        <input
          type="date"
          class="bg-transparent w-28 text-center text-sm"
          formControlName="startDate"
          required
          [max]="formControls.endDate.value"
        />
      </label>
      -
      <label>
        <input
          type="date"
          class="bg-transparent w-28 text-center text-sm"
          formControlName="endDate"
          required
          [max]="maxDate"
          [min]="formControls.startDate.value"
        />
      </label>
    </div>
  </form>
  <div
    class="h-full w-[90%] flex flex-col padding-2 desktop:w-[95%] padding-2 rounded-lg"
  >
    <div
      *ngIf="!hasSensorDataLocation"
      class="flex h-[95%] w-full justify-center items-center text-white"
    >
      {{ 'dashboard-page.sensor-viewer-panel.no-sensor-data' | translate }}
    </div>

    <!-- soil temperature -->
    <div
      class="relative bg-sunkenBox shadow-sunkenBoxShadow invisible w-0"
      [ngClass]="{
        'visible-chart': hasSensorDataLocation,
        '!h-0': !hasSensorDataLocation
      }"
    >
      <canvas
        #soilTemperatureForLandCanvas
        id="soilTemperatureForLandCanvas"
        [ngClass]="{
          '!h-0': !hasSensorDataLocation
        }"
      ></canvas>
      <div
        *ngIf="
          getChartResolution(chartNameEnum.soilTemperatureForLandCanvas).resolutions
            .length > 1
        "
      >
        <dfarm-resolution-button-group
          [resolutions]="
            getChartResolution(chartNameEnum.soilTemperatureForLandCanvas).resolutions
          "
          (resolutionChanged)="
            changeChartResolution($event, chartNameEnum.soilTemperatureForLandCanvas)
          "
        ></dfarm-resolution-button-group>
      </div>
    </div>
    <!-- soil moisture -->
    <div
      class="relative bg-sunkenBox shadow-sunkenBoxShadow invisible w-0"
      [ngClass]="{
        'visible-chart': hasSensorDataLocation,
        '!h-0': !hasSensorDataLocation
      }"
    >
      <canvas
        #soilMoistureForLandCanvas
        id="soilMoistureForLandCanvas"
        [ngClass]="{
          '!h-0': !hasSensorDataLocation
        }"
      ></canvas>
      <div
        *ngIf="
          getChartResolution(chartNameEnum.soilMoistureForLandCanvas).resolutions
            .length > 1
        "
      >
        <dfarm-resolution-button-group
          [resolutions]="
            getChartResolution(chartNameEnum.soilMoistureForLandCanvas).resolutions
          "
          (resolutionChanged)="
            changeChartResolution($event, chartNameEnum.soilMoistureForLandCanvas)
          "
        ></dfarm-resolution-button-group>
      </div>
    </div>
  </div>
</div>
