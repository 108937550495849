<nav class="h-12">
  <div class="hamburger-menu">
    <div
      id="nav-icon3"
      [ngClass]="{ open: menuIsOpen }"
      (click)="openAndCloseMenu()"
    >
      <span></span>
      <span></span>
      <span></span>
      <span></span>
    </div>
  </div>
  <div
    class="text-2xl text-white relative m-auto font-medium flex items-center gap-2"
  >
    <div>
      <img
        class="h-8 w-8"
        src="../../../../assets/dfarm-logo-2.svg"
        alt="logo"
      />
    </div>
    <div class="mobileVertical:pr-0">dFarm</div>
  </div>
  <div class="profile-wrapper">
    <img
      src="../../../../assets/header-icons/user.svg"
      alt="user"
      [matMenuTriggerFor]="menu"
    />
    <!-- <img src="../../../../assets/header-icons/bell.svg" alt="notification" />
    <img src="../../../../assets/header-icons/chat.svg" alt="chat" /> -->
  </div>
</nav>
<mat-menu #menu="matMenu" class="custom-menu">
  <button mat-menu-item (click)="openProfileDialog()">
    {{ 'navbar.profile-overview' | translate }}
  </button>
  <!-- <button mat-menu-item (click)="openInvoiceDialog()">Számlázási adatok</button> -->
  <button mat-menu-item (click)="openChangePasswordDialog()">
    {{ 'navbar.edit-password' | translate }}
  </button>
  <button mat-menu-item (click)="logOut()">
    {{ 'navbar.logout' | translate }}
  </button>
</mat-menu>
