import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import { IChangePasswordViewModel, IEditProfileViewModel } from '../models/edit-profile.model';

@Injectable({
  providedIn: 'root',
})
export class ProfileService {
  private baseUrl = environment.baseUrl;
  constructor(private http: HttpClient) {}

  getUser(): Observable<IEditProfileViewModel> {
    const url = this.baseUrl + 'accounts';
    return this.http
      .get<any>(url, {
        withCredentials: true,
        observe: 'response',
      })
      .pipe(
        map(
          (dto): IEditProfileViewModel => ({
            email: dto.body.email,
            firstName: dto.body.firstName,
            lastName: dto.body.lastName,
            phoneNumber: dto.body.phoneNumber,
          })
        )
      );
  }

  updateUser(updatedUser: IEditProfileViewModel): Observable<void> {
    const url = this.baseUrl + 'accounts';
    return this.http.put<any>(url, { ...updatedUser });
  }

  changePassword(newPassword: IChangePasswordViewModel): Observable<void> {
    const url = this.baseUrl + 'accounts/change-password';
    return this.http.post<any>(url, { ...newPassword });
  }
}
