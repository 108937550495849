import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Route, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { TranslateLoaderService } from './translate.loader.service';

@Injectable({
  providedIn: 'root',
})
export class TranslateModuleResolver  {
  constructor(private translateLoader: TranslateLoaderService) {}

  canLoad(
    route: Route
  ):
    | boolean
    | UrlTree
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree> {
    return this.translateLoader
      .loadTranslation(route.data?.translate)
      .pipe(map(() => true));
  }

  public resolve(
    route: ActivatedRouteSnapshot
  ): Observable<boolean> | Promise<boolean> | boolean {
    return this.translateLoader
      .loadTranslation(route.data?.translate)
      .pipe(map(() => true));
  }
}
