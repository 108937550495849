import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { CorePartialState } from 'src/app/core/state/core.reducer';
import { AuthService } from 'src/app/pages/login/auth.service';
import * as CoreActions from '../../../../core/state/core.actions';
import * as CoreQueries from '../../../../core/state/core.selectors';
import { FieldDetailsViewModel } from '../../models/field.model';
import { DashboardPartialState } from '../../state/dashboard.reducer';
import * as DashboardQueries from '../../state/dashboard.selectors';
import { BasePanelComponent } from '../base-panel.component';
import { RoleTypesEnum } from '../../../../shared/enums/role-type.enum';

@Component({
  selector: 'dfarm-satellite-viewer',
  templateUrl: './satellite-viewer.component.html',
  styleUrls: ['./satellite-viewer.component.scss'],
})
export class SatelliteViewerComponent
  extends BasePanelComponent
  implements OnInit, OnDestroy {
  @Input() fields: FieldDetailsViewModel[] = [];
  @Output() selectNextFieldOutput: EventEmitter<void> = new EventEmitter<void>();
  @Output() selectPreviousFieldOutput: EventEmitter<void> = new EventEmitter<void>();

  selectedField: FieldDetailsViewModel;

  constructor(
    private readonly coreStore: Store<CorePartialState>,
    private readonly authService: AuthService,
    private readonly dashboardStore: Store<DashboardPartialState>,
  ) {
    super();
  }

  ngOnInit(): void {
    this.subs.push(
      // GET Field Inspector Menu is open
      this.coreStore
        .pipe(select(CoreQueries.getSatelliteViewerPanelIsOpen))
        .subscribe((panelIsOpen) => {
          this.panelIsOpen = panelIsOpen;
          this.isLoading = false;
        }),
      this.dashboardStore
        .pipe(select(DashboardQueries.getSelectedField))
        .subscribe((selectedField: FieldDetailsViewModel) => {
          if (selectedField !== undefined) {
            this.selectedField = selectedField;
          }
        }),
    );
    this.subs.push(
      this.authService.userRoles.subscribe((roles: RoleTypesEnum[]) => (this.userRoles = roles)),
    );
  }

  selectNextField(): void {
    this.selectNextFieldOutput.emit();
  }

  selectPreviousField(): void {
    this.selectPreviousFieldOutput.emit();
  }

  showAddFieldPanel(): void {
    this.coreStore.dispatch(CoreActions.closeAllPanel());
    this.coreStore.dispatch(CoreActions.openAddFieldPanel());
    this.coreStore.dispatch(CoreActions.closeSideMenu());
  }

  get hasPermission(): boolean {
    return (
      this.userRoles.some((roleTypesEnum: RoleTypesEnum) => roleTypesEnum === this.RoleTypesEnum.Basic) ||
      this.userRoles.some((roleTypesEnum: RoleTypesEnum) => roleTypesEnum === this.RoleTypesEnum.Trial)
    );
  }

  ngOnDestroy(): void {
    this.subs.forEach((sub) => sub.unsubscribe());
  }
}
