import { APP_INITIALIZER, Provider } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { combineLatest } from 'rxjs';
import { take } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { TranslateLoaderService } from './translate.loader.service';

export const initTranslate = (translateService: TranslateService): void => {
  translateService.addLangs(environment.languages);
  translateService.setDefaultLang(environment.defaultLanguage);
  if (!localStorage.getItem('language')) {
    localStorage.setItem('language', environment.defaultLanguage);
  }
  translateService.use(
    localStorage.getItem('language') ?? environment.defaultLanguage
  );
};

const translateInitializerFactory =
  (translateLoader: TranslateLoaderService): (() => Promise<any>) =>
  () =>
    combineLatest([
      translateLoader.loadTranslation('en'),
      translateLoader.loadTranslation('hu'),
      translateLoader.loadTranslation('de'),
      translateLoader.loadTranslation('sk'),
    ])
      .pipe(take(1))
      .toPromise();

export const translateInitializerProvider: Provider = {
  provide: APP_INITIALIZER,
  useFactory: translateInitializerFactory,
  deps: [TranslateLoaderService],
  multi: true,
};
