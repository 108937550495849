import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Store, select } from '@ngrx/store';
import { DeviceDetectorService } from 'ngx-device-detector';
import { Subscription } from 'rxjs';
import { CorePartialState } from 'src/app/core/state/core.reducer';
import { AuthService } from 'src/app/pages/login/auth.service';
import * as CoreActions from '../../../core/state/core.actions';
import * as CoreQueries from '../../../core/state/core.selectors';
import { ChangePasswordDialogComponent } from '../change-password-dialog/change-password-dialog.component';
import { InvoiceDialogComponent } from '../invoice-dialog/invoice-dialog.component';
import { ProfileDialogComponent } from '../profile-dialog/profile-dialog.component';
import { CommonModule } from '@angular/common';
import { MatMenuModule } from '@angular/material/menu';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'dfarm-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss'],
  standalone: true,
  imports: [CommonModule, MatMenuModule, TranslateModule],
})
export class NavbarComponent implements OnInit, OnDestroy {
  subs: Subscription[] = [];
  menuIsOpen: boolean;

  constructor(
    private readonly store: Store<CorePartialState>,
    private readonly deviceDetectorService: DeviceDetectorService,
    private readonly authService: AuthService,
    public dialog: MatDialog,
  ) {}

  ngOnInit(): void {
    this.subs.push(
      // GET Side Menu is open
      this.store
        .pipe(select(CoreQueries.getSideMenuIsOpen))
        .subscribe((menuIsOpen) => (this.menuIsOpen = menuIsOpen)),
    );
  }

  openAndCloseMenu(): void {
    this.menuIsOpen
      ? this.store.dispatch(CoreActions.closeSideMenu())
      : this.store.dispatch(CoreActions.openSideMenu());
  }

  openProfileDialog(): void {
    if (
      this.deviceDetectorService.isMobile() &&
      this.deviceDetectorService.orientation === 'portrait'
    ) {
      const dialogRef = this.dialog.open(ProfileDialogComponent, {
        maxWidth: '20rem',
        width: '95%',
      });

      dialogRef.afterClosed().subscribe();
    } else if (
      this.deviceDetectorService.isMobile() &&
      this.deviceDetectorService.orientation === 'landscape'
    ) {
      const dialogRef = this.dialog.open(ProfileDialogComponent, {
        maxWidth: '20rem',
        width: '95%',
        maxHeight: '23rem',
      });

      dialogRef.afterClosed().subscribe();
    } else if (
      this.deviceDetectorService.isTablet() &&
      this.deviceDetectorService.orientation === 'portrait'
    ) {
      const dialogRef = this.dialog.open(ProfileDialogComponent, {
        maxWidth: '36rem',
        width: '80%',
      });

      dialogRef.afterClosed().subscribe();
    } else {
      const dialogRef = this.dialog.open(ProfileDialogComponent, {
        maxWidth: '36rem',
        width: '60%',
      });

      dialogRef.afterClosed().subscribe();
    }
  }

  openInvoiceDialog(): void {
    if (
      this.deviceDetectorService.isMobile() &&
      this.deviceDetectorService.orientation === 'portrait'
    ) {
      const dialogRef = this.dialog.open(InvoiceDialogComponent, {
        maxWidth: '20rem',
        width: '95%',
      });

      dialogRef.afterClosed().subscribe();
    } else if (
      this.deviceDetectorService.isMobile() &&
      this.deviceDetectorService.orientation === 'landscape'
    ) {
      const dialogRef = this.dialog.open(InvoiceDialogComponent, {
        maxWidth: '20rem',
        width: '95%',
        maxHeight: '23rem',
      });

      dialogRef.afterClosed().subscribe();
    } else if (
      this.deviceDetectorService.isTablet() &&
      this.deviceDetectorService.orientation === 'portrait'
    ) {
      const dialogRef = this.dialog.open(InvoiceDialogComponent, {
        maxWidth: '36rem',
        width: '80%',
      });

      dialogRef.afterClosed().subscribe();
    } else {
      const dialogRef = this.dialog.open(InvoiceDialogComponent, {
        maxWidth: '36rem',
        width: '60%',
      });

      dialogRef.afterClosed().subscribe();
    }
  }

  openChangePasswordDialog(): void {
    if (
      this.deviceDetectorService.isMobile() &&
      this.deviceDetectorService.orientation === 'portrait'
    ) {
      const dialogRef = this.dialog.open(ChangePasswordDialogComponent, {
        maxWidth: '20rem',
        width: '95%',
      });

      dialogRef.afterClosed().subscribe();
    } else if (
      this.deviceDetectorService.isMobile() &&
      this.deviceDetectorService.orientation === 'landscape'
    ) {
      const dialogRef = this.dialog.open(ChangePasswordDialogComponent, {
        maxWidth: '20rem',
        width: '95%',
        maxHeight: '23rem',
      });

      dialogRef.afterClosed().subscribe();
    } else {
      const dialogRef = this.dialog.open(ChangePasswordDialogComponent, {
        maxWidth: '20rem',
        width: '40%',
      });

      dialogRef.afterClosed().subscribe();
    }
  }

  logOut(): void {
    this.authService.logout();
  }

  ngOnDestroy(): void {
    this.subs.forEach((sub) => sub.unsubscribe());
  }
}
