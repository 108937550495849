import { createAction } from '@ngrx/store';

// LOAD Core Data
export const loadCoreData = createAction('[Core] Load Core Data');
export const closeAllPanel = createAction('[Core] Close All Panel');

// Control Side Menu
export const closeSideMenu = createAction('[Core] Close Side Menu');
export const openSideMenu = createAction('[Core] Open Side Menu');

// Control Add Field Panel
export const closeAddFieldPanel = createAction('[Core] Close Add Field Panel');
export const openAddFieldPanel = createAction('[Core] Open Add Field Panel');

// Control Data Viewer Panel
export const closeDataViewerPanel = createAction(
  '[Core] Close Data Viewer Panel',
);
export const openDataViewerPanel = createAction(
  '[Core] Open Data Viewer Panel',
);

// Control Satellite Viewer Panel
export const closeSatelliteViewerPanel = createAction(
  '[Core] Close Satellite Viewer Panel',
);
export const openSatelliteViewerPanel = createAction(
  '[Core] Open Satellite Viewer Panel',
);

// Control Sensor Viewer Panel
export const closeSensorViewerPanel = createAction(
  '[Core] Close Sensor Viewer Panel',
);
export const openSensorViewerPanel = createAction(
  '[Core] Open Sensor Viewer Panel',
);

// Control Add Field Inspector Panel
export const closeFieldInspectorPanel = createAction(
  '[Core] Close Field Inspector Panel',
);
export const openFieldInspectorPanel = createAction(
  '[Core] Open Field Inspector Panel',
);

// Control Add Field Inspector Panel
export const closeDfarmServicesPanel = createAction(
  '[Core] Close Dfarm Services Panel',
);
export const openDfarmServicesPanel = createAction(
  '[Core] Open Dfarm Services Panel',
);

// Control Information Panel
export const closeInformationPanel = createAction(
  '[Core] Close Information Panel',
);
export const openInformationPanel = createAction(
  '[Core] Open Information Panel',
);

// Waterscope Item List Panel
export const closeWaterscopeItemListPanel = createAction(
  '[Core] Close Waterscope Item List Panel',
);
export const openWaterscopeItemListPanel = createAction(
  '[Core] Open Waterscope Item List Panel',
);

// Waterscope Table Panel
export const closeWaterscopeTablePanel = createAction(
  '[Core] Close Waterscope Table Panel',
);
export const openWaterscopeTablePanel = createAction(
  '[Core] Open Waterscope Table Panel',
);

// Waterscope Chart Panel
export const closeWaterscopeChartPanel = createAction(
  '[Core] Close Waterscope Chart Panel',
);
export const openWaterscopeChartPanel = createAction(
  '[Core] Open Waterscope Chart Panel',
);
