// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  baseUrl: 'https://dev.backend.dfarm.hu/api/',
  sensorBaseUrl: 'https://staging.sensor-data.dfarm.hu/v4/',
  mapbox: {
    accessToken:
      'pk.eyJ1IjoiZGZhcm0iLCJhIjoiY2tqb3Bqc3VvMGZycTJ6bDF3c3ZpNTk2eiJ9.Nun3aH283_0m-5vtsU8yNg',
  },
  languages: ['hu', 'en'],
  defaultLanguage: 'hu',
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
