import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { select, Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { Chart, registerables } from 'chart.js';
import { DeviceDetectorService } from 'ngx-device-detector';
import { forkJoin, Subscription } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { CorePartialState } from 'src/app/core/state/core.reducer';
import { IDateRangeSelectorForm } from 'src/app/pages/dashboard/models/agromonitoring-data.model';
import { FieldDetailsViewModel } from 'src/app/pages/dashboard/models/field.model';
import {
  ChartNameEnum,
  ChartResolutionMap,
  ChartSelectedResolutionMap,
  ISoilMoistureForLandViewModel,
  ISoilTemperatureForLandViewModel,
  SensorDataResolutionEnum,
} from 'src/app/pages/dashboard/models/sensor-data.model';
import { SensorDataService } from 'src/app/pages/dashboard/services/sensor-data.service';
import { DashboardPartialState } from 'src/app/pages/dashboard/state/dashboard.reducer';
import * as CoreQueries from '../../../../../../core/state/core.selectors';
import * as DashboardQueries from '../../../../../dashboard/state/dashboard.selectors';

@Component({
  selector: 'dfarm-sensor-viewer-overview',
  templateUrl: './sensor-viewer-overview.component.html',
  styleUrls: ['./sensor-viewer-overview.component.scss'],
})
export class SensorViewerOverviewComponent implements OnInit, OnDestroy {
  @ViewChild('soilTemperatureForLandCanvas', { static: true })
  private soilTemperatureForLandCanvas: ElementRef;
  soilTemperatureForLandChart: any;

  @ViewChild('soilMoistureForLandCanvas', { static: true })
  private soilMoistureForLandCanvas: ElementRef;
  soilMoistureLandChart: any;

  subs: Subscription[] = [];
  sensorViewerPanelIsOpen = false;

  formControls: Record<keyof IDateRangeSelectorForm, FormControl> = {
    startDate: new FormControl(new Date()),
    endDate: new FormControl(new Date()),
  };
  dateRangeSelectorForm = new FormGroup(this.formControls);

  @Input() intervalStart: Date;
  @Input() intervalEnd: Date;
  @Input() maxDate: string;
  @Output() intervalStartChange = new EventEmitter<Date>();
  @Output() intervalEndChange = new EventEmitter<Date>();

  intervalStartChangeEmitted = false;
  intervalEndChangeEmitted = false;

  selectedField: FieldDetailsViewModel;
  @Input() hasSensorDataLocation = false;

  soilTemperatureData: ISoilTemperatureForLandViewModel;
  soilMoistureData: ISoilMoistureForLandViewModel;

  chartNameEnum = ChartNameEnum;
  chartResolutionMap: ChartResolutionMap[] = [];
  chartSelectedResolutionMap: ChartSelectedResolutionMap[] = [];

  constructor(
    private readonly coreStore: Store<CorePartialState>,
    private readonly dashboardStore: Store<DashboardPartialState>,
    private readonly deviceDetectorService: DeviceDetectorService,
    private readonly translateService: TranslateService,
    private readonly sensorDataService: SensorDataService
  ) {}

  ngOnChanges(changes: SimpleChanges): void {
    for (const propName in changes) {
      const changedProp = changes[propName];

      if (
        propName === 'intervalStart' &&
        changedProp.currentValue !== undefined
      ) {
        if (!this.intervalStartChangeEmitted) {
          this.formControls.startDate.setValue(
            this.intervalStart.toISOString().slice(0, 10),
            {
              emitEvent: false,
            }
          );
        } else {
          this.intervalStartChangeEmitted = false;
        }

        if (this.sensorViewerPanelIsOpen) {
          this.clearAllCharts();
          this.getSensorDataForLand();
        }
      } else if (
        propName === 'intervalEnd' &&
        changedProp.currentValue !== undefined
      ) {
        if (!this.intervalEndChangeEmitted) {
          this.formControls.endDate.setValue(
            this.intervalEnd.toISOString().slice(0, 10),
            {
              emitEvent: false,
            }
          );
        } else {
          this.intervalEndChangeEmitted = false;
        }

        if (this.sensorViewerPanelIsOpen) {
          this.clearAllCharts();
          this.getSensorDataForLand();
        }
      }
    }
  }

  ngOnInit(): void {
    this.chartResolutionMap = this.sensorDataService.getChartResolutionMap();
    this.chartSelectedResolutionMap =
      this.sensorDataService.getChartSelectedResolutionMap();

    this.subs.push(
      this.dateRangeSelectorForm.valueChanges
        .pipe(debounceTime(1000))
        .subscribe(() => {
          if (
            new Date(this.formControls.startDate.value).setHours(0, 0, 0, 0) !==
            this.intervalStart.setHours(0, 0, 0, 0)
          ) {
            this.intervalStart = new Date(this.formControls.startDate.value);
            this.intervalStartChange.emit(this.intervalStart);
            this.intervalStartChangeEmitted = true;
          }

          if (
            new Date(this.formControls.endDate.value).setHours(0, 0, 0, 0) !==
            this.intervalEnd.setHours(0, 0, 0, 0)
          ) {
            this.intervalEnd = new Date(this.formControls.endDate.value);
            this.intervalEndChange.emit(this.intervalEnd);
            this.intervalEndChangeEmitted = true;
          }
        })
    );

    this.subs.push(
      this.coreStore
        .pipe(select(CoreQueries.getSensorViewerPanelIsOpen))
        .subscribe((panelIsOpen) => {
          this.sensorViewerPanelIsOpen = panelIsOpen;
          if (panelIsOpen) {
            this.getSensorDataForLand();
          } else {
            this.clearAllCharts();
          }
        })
    );

    this.subs.push(
      this.dashboardStore
        .pipe(select(DashboardQueries.getSelectedField))
        .subscribe((selectedField) => {
          if (selectedField !== undefined) {
            this.selectedField = selectedField;

            if (this.sensorViewerPanelIsOpen) {
              this.clearAllCharts();
              this.getSensorDataForLand();
            }
          }
        })
    );

    Chart.register(...registerables);

    this.soilTemperatureForLandChart = new Chart(
      this.soilTemperatureForLandCanvas.nativeElement,
      {
        type: 'line',
        data: {
          datasets: [],
        },
        options: {
          interaction: {
            intersect: false,
            mode: 'index',
          },
          scales: {
            x: {
              offset: true,
              ticks: {
                display: !this.deviceDetectorService.isMobile(),
                color: 'rgba(255, 255, 255, 0.75)',
              },
              title: {
                display: false,
                text: this.translateService.instant(
                  'dashboard-page.sensor-viewer-panel.date'
                ),
                color: 'rgba(255, 255, 255, 0.75)',
              },
            },
            y: {
              ticks: {
                color: 'rgba(255, 255, 255, 0.75)',
              },
              title: {
                display: false,
                text: this.translateService.instant(
                  'dashboard-page.sensor-viewer-panel.soil-temperature-with-degree'
                ),
                color: 'rgba(255, 255, 255, 0.75)',
              },
              grid: {
                drawBorder: false,
                color: function(context) {
                  return 'black';
                },
              },
            },
          },
          plugins: {
            title: {
              display: true,
              text: this.translateService.instant(
                'dashboard-page.sensor-viewer-panel.soil-temperature-with-degree'
              ),
              color: 'white',
              font: {
                size: 14,
              },
              padding: {
                bottom: 5,
              },
            },
            legend: {
              display: !this.deviceDetectorService.isMobile(),
              labels: {
                color: 'rgba(255, 255, 255, 0.75)',
              },
            },
          },
          layout: {
            padding: 10,
          },
        },
      }
    );

    this.soilMoistureLandChart = new Chart(
      this.soilMoistureForLandCanvas.nativeElement,
      {
        type: 'line',
        data: {
          datasets: [],
        },
        options: {
          interaction: {
            intersect: false,
            mode: 'index',
          },
          scales: {
            x: {
              offset: true,
              ticks: {
                display: !this.deviceDetectorService.isMobile(),
                color: 'rgba(255, 255, 255, 0.75)',
              },
              title: {
                display: false,
                text: this.translateService.instant(
                  'dashboard-page.sensor-viewer-panel.date'
                ),
                color: 'rgba(255, 255, 255, 0.75)',
              },
            },
            y: {
              min: 0,
              max: 100,
              ticks: {
                color: 'rgba(255, 255, 255, 0.75)',
              },
              title: {
                display: false,
                text: this.translateService.instant(
                  'dashboard-page.sensor-viewer-panel.soil-moisture-with-percent'
                ),
                color: 'rgba(255, 255, 255, 0.75)',
              },
              grid: {
                drawBorder: false,
                color: function(context) {
                  return 'black';
                },
              },
            },
          },
          plugins: {
            title: {
              display: true,
              text: this.translateService.instant(
                'dashboard-page.sensor-viewer-panel.soil-moisture-with-percent'
              ),
              color: 'white',
              font: {
                size: 14,
              },
              padding: {
                bottom: 5,
              },
            },
            legend: {
              display: !this.deviceDetectorService.isMobile(),
              labels: {
                color: 'rgba(255, 255, 255, 0.75)',
              },
            },
          },
          layout: {
            padding: 10,
          },
        },
      }
    );
  }

  changeChartResolution(
    sensorDataResolutionEnum: SensorDataResolutionEnum,
    chartNameEnum: ChartNameEnum
  ): void {
    const intervalStart = new Date(this.formControls.startDate.value);
    const intervalEnd = new Date(this.formControls.endDate.value);
    intervalStart.setHours(0, 0, 0, 0);
    intervalEnd.setHours(23, 59, 0, 0);

    switch (chartNameEnum.valueOf()) {
      case ChartNameEnum.soilTemperatureForLandCanvas.valueOf():
        const soilTemperatureChartResolution =
          this.chartSelectedResolutionMap.find(
            (x) => x.chartName === chartNameEnum
          );
        soilTemperatureChartResolution.selectedResolution =
          sensorDataResolutionEnum;

        this.sensorDataService
          .getSoilTemperature(
            this.selectedField.id,
            intervalStart,
            intervalEnd,
            soilTemperatureChartResolution.selectedResolution
          )
          .subscribe((soilTemperature) => {
            this.soilTemperatureData = soilTemperature;
            this.clearSoilTemperatureChart();
            this.updateSoilTemperatureChart(
              soilTemperatureChartResolution.selectedResolution
            );
          });
        break;

      case ChartNameEnum.soilMoistureForLandCanvas.valueOf():
        const soilMoistureChartResolution =
          this.chartSelectedResolutionMap.find(
            (x) => x.chartName === chartNameEnum
          );
        soilMoistureChartResolution.selectedResolution =
          sensorDataResolutionEnum;

        this.sensorDataService
          .getSoilMoisture(
            this.selectedField.id,
            intervalStart,
            intervalEnd,
            soilMoistureChartResolution.selectedResolution
          )
          .subscribe((soilMoisture) => {
            this.soilMoistureData = soilMoisture;
            this.clearSoilMoistureChart();
            this.updateSoilMoistureChart(
              soilMoistureChartResolution.selectedResolution
            );
          });
        break;
      default:
        break;
    }
  }

  getChartResolution(chartNameEnum: ChartNameEnum): ChartResolutionMap {
    return this.chartResolutionMap.find((x) => x.chartName === chartNameEnum);
  }

  private getSensorDataForLand(): void {
    if (this.dateRangeSelectorForm.valid && this.selectedField !== undefined) {
      const intervalStart = new Date(this.formControls.startDate.value);
      const intervalEnd = new Date(this.formControls.endDate.value);
      intervalStart.setHours(0, 0, 0, 0);
      intervalEnd.setHours(23, 59, 0, 0);

      const soilTemperatureChartResolution =
        this.chartSelectedResolutionMap.find(
          (x) => x.chartName === ChartNameEnum.soilTemperatureForLandCanvas
        ).selectedResolution;
      const soilMoistureChartResolution = this.chartSelectedResolutionMap.find(
        (x) => x.chartName === ChartNameEnum.soilMoistureForLandCanvas
      ).selectedResolution;

      forkJoin([
        this.sensorDataService.getSoilTemperature(
          this.selectedField.id,
          intervalStart,
          intervalEnd,
          soilTemperatureChartResolution
        ),
        this.sensorDataService.getSoilMoisture(
          this.selectedField.id,
          intervalStart,
          intervalEnd,
          soilMoistureChartResolution
        ),
      ]).subscribe(([soilTemperature, soilMoisture]) => {
        this.soilTemperatureData = soilTemperature;
        this.soilMoistureData = soilMoisture;

        this.updateSoilTemperatureChart(soilTemperatureChartResolution);
        this.updateSoilMoistureChart(soilMoistureChartResolution);
      });
    }
  }

  private clearSoilTemperatureChart(): void {
    if (this.soilTemperatureForLandChart === undefined) {
      return;
    }

    this.soilTemperatureForLandChart.data.labels = [];
    this.soilTemperatureForLandChart.data.datasets = [];

    this.soilTemperatureForLandChart.update();
  }

  private updateSoilTemperatureChart(
    sensorDataResolutionEnum: SensorDataResolutionEnum
  ) {
    const newLabels = [];
    const newDatasets = [];

    if (sensorDataResolutionEnum === SensorDataResolutionEnum.hourly) {
      this.soilTemperatureData.hourly.time.forEach((time) => {
        newLabels.push(time.toLocaleString());
      });

      const averageDataset = {
        label: this.translateService.instant(
          'dashboard-page.sensor-viewer-panel.average'
        ),
        data: [],
        borderColor: 'rgba(255, 128, 0, 0.8)',
        backgroundColor: 'rgba(255, 128, 0, 0.6)',
      };
      this.soilTemperatureData.hourly.average.forEach((average) => {
        averageDataset.data.push(average);
      });
      newDatasets.push(averageDataset);
    } else if (sensorDataResolutionEnum === SensorDataResolutionEnum.daily) {
      this.soilTemperatureData.daily.time.forEach((time) => {
        newLabels.push(time.toLocaleDateString());
      });

      const minimumDataset = {
        label: this.translateService.instant(
          'dashboard-page.sensor-viewer-panel.minimum'
        ),
        data: [],
        borderColor: 'rgba(0, 128, 255, 0.8)',
        backgroundColor: 'rgba(0, 128, 255, 0.6)',
      };
      this.soilTemperatureData.daily.minimum.forEach((minimum) => {
        minimumDataset.data.push(minimum);
      });
      newDatasets.push(minimumDataset);

      const averageDataset = {
        label: this.translateService.instant(
          'dashboard-page.sensor-viewer-panel.average'
        ),
        data: [],
        borderColor: 'rgba(255, 128, 0, 0.8)',
        backgroundColor: 'rgba(255, 128, 0, 0.6)',
      };
      this.soilTemperatureData.daily.average.forEach((average) => {
        averageDataset.data.push(average);
      });
      newDatasets.push(averageDataset);

      const maximumDataset = {
        label: this.translateService.instant(
          'dashboard-page.sensor-viewer-panel.maximum'
        ),
        data: [],
        borderColor: 'rgba(255, 0, 0, 0.8)',
        backgroundColor: 'rgba(255, 0, 0, 0.6)',
      };
      this.soilTemperatureData.daily.maximum.forEach((maximum) => {
        maximumDataset.data.push(maximum);
      });
      newDatasets.push(maximumDataset);
    }

    newLabels.forEach((label) => {
      this.soilTemperatureForLandChart.data.labels.push(label);
    });
    newDatasets.forEach((dataset) => {
      this.soilTemperatureForLandChart.data.datasets.push(dataset);
    });

    this.soilTemperatureForLandChart.update();
  }

  private clearSoilMoistureChart(): void {
    if (this.soilMoistureLandChart === undefined) {
      return;
    }

    this.soilMoistureLandChart.data.labels = [];
    this.soilMoistureLandChart.data.datasets = [];

    this.soilMoistureLandChart.update();
  }

  private updateSoilMoistureChart(
    sensorDataResolutionEnum: SensorDataResolutionEnum
  ) {
    const newLabels = [];
    const newDatasets = [];

    if (sensorDataResolutionEnum === SensorDataResolutionEnum.hourly) {
      this.soilMoistureData.hourly.time.forEach((time) => {
        newLabels.push(time.toLocaleString());
      });

      const averageDataset = {
        label: this.translateService.instant(
          'dashboard-page.sensor-viewer-panel.average'
        ),
        data: [],
        borderColor: 'rgba(0, 128, 255, 0.8)',
        backgroundColor: 'rgba(0, 128, 255, 0.6)',
      };
      this.soilMoistureData.hourly.average.forEach((average) => {
        averageDataset.data.push(average);
      });
      newDatasets.push(averageDataset);
    } else if (sensorDataResolutionEnum === SensorDataResolutionEnum.daily) {
      this.soilMoistureData.daily.time.forEach((time) => {
        newLabels.push(time.toLocaleDateString());
      });

      const minimumDataset = {
        label: this.translateService.instant(
          'dashboard-page.sensor-viewer-panel.minimum'
        ),
        data: [],
        borderColor: 'rgba(0, 128, 255, 0.8)',
        backgroundColor: 'rgba(0, 128, 255, 0.6)',
      };
      this.soilMoistureData.daily.minimum.forEach((minimum) => {
        minimumDataset.data.push(minimum);
      });
      newDatasets.push(minimumDataset);

      const averageDataset = {
        label: this.translateService.instant(
          'dashboard-page.sensor-viewer-panel.average'
        ),
        data: [],
        borderColor: 'rgba(255, 128, 0, 0.8)',
        backgroundColor: 'rgba(255, 128, 0, 0.6)',
      };
      this.soilMoistureData.daily.average.forEach((average) => {
        averageDataset.data.push(average);
      });
      newDatasets.push(averageDataset);

      const maximumDataset = {
        label: this.translateService.instant(
          'dashboard-page.sensor-viewer-panel.maximum'
        ),
        data: [],
        borderColor: 'rgba(255, 0, 0, 0.8)',
        backgroundColor: 'rgba(255, 0, 0, 0.6)',
      };
      this.soilMoistureData.daily.maximum.forEach((maximum) => {
        maximumDataset.data.push(maximum);
      });
      newDatasets.push(maximumDataset);
    }

    newLabels.forEach((label) => {
      this.soilMoistureLandChart.data.labels.push(label);
    });
    newDatasets.forEach((dataset) => {
      this.soilMoistureLandChart.data.datasets.push(dataset);
    });

    this.soilMoistureLandChart.update();
  }

  private clearAllCharts(): void {
    this.clearSoilTemperatureChart();
    this.clearSoilMoistureChart();
  }

  ngOnDestroy(): void {
    this.subs.forEach((sub) => sub.unsubscribe());
  }
}
