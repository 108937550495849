<dfarm-panel class="parent-panel" [isVisible]="dfarmServicesIsOpen" [isLeftSide]="false" [isClosable]="true"
             *ngIf="!isLoading" [isWidePanel]="true">
  <div class="child-panel">
    <div class="title">
      Szolgáltatások áttekintése
    </div>

    <div class="field-list-wrapper">
      <div class="field-list">
        <!-- <div class="field-list-item" *ngFor="let field of fieldsWithServices">
            <div class="field-list-item-title">{{ field.fieldName }}</div>
        </div> -->
        <table mat-table [dataSource]="fieldsWithServices" class="mat-elevation-z8">

          <!-- Field Name Column -->
          <ng-container matColumnDef="fieldName">
            <th mat-header-cell *matHeaderCellDef> Föld neve</th>
            <td mat-cell *matCellDef="let element"> {{ element.fieldName }}</td>
          </ng-container>

          <!-- Satelite Column -->
          <ng-container matColumnDef="isSatelite">
            <th mat-header-cell *matHeaderCellDef> Műhold</th>
            <td mat-cell *matCellDef="let element">
              <img *ngIf="!element.isSatelite" src="../../../../../assets/utility-icons/lock.svg"
                   alt="lock">
              <img *ngIf="element.isSatelite" src="../../../../../assets/utility-icons/checked.svg"
                   alt="checked">
            </td>
          </ng-container>

          <!-- Sensor Column -->
          <ng-container matColumnDef="isSensor">
            <th mat-header-cell *matHeaderCellDef> Szenzor</th>
            <td mat-cell *matCellDef="let element">
              <img *ngIf="!element.isSensor" src="../../../../../assets/utility-icons/lock.svg"
                   alt="lock">
              <img *ngIf="element.isSensor" src="../../../../../assets/utility-icons/checked.svg"
                   alt="checked">
            </td>
          </ng-container>

          <!-- Drone Column -->
          <ng-container matColumnDef="isDrone">
            <th mat-header-cell *matHeaderCellDef> Drón</th>
            <td mat-cell *matCellDef="let element">
              <img *ngIf="!element.isDrone" src="../../../../../assets/utility-icons/lock.svg" alt="lock">
              <img *ngIf="element.isDrone" src="../../../../../assets/utility-icons/checked.svg"
                   alt="checked">
            </td>
          </ng-container>

          <!-- Drone Column -->
          <ng-container matColumnDef="editing" width="20%">
            <th mat-header-cell *matHeaderCellDef></th>
            <td mat-cell *matCellDef="let element">
              <button class="orange-button">Módosítás</button>
            </td>
          </ng-container>

          <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
        </table>


      </div>
    </div>
  </div>
</dfarm-panel>
