<div class="dialog-wrapper">
  <div class="form-title">{{ 'dialogs.profile.title' | translate }}</div>
  <form [formGroup]="editProfileFormGroup" class="edit-profile-form">
    <div class="left-form">
      <div class="input-wrapper">
        <label class="field-name-label">
          {{ 'dialogs.profile.lastname' | translate }}
        </label>
        <input
          name="name"
          type="text"
          [ngClass]="{
            '!bg-[#505050] !text-[#A9A9A9]': formControls.lastName.disabled
          }"
          [formControl]="formControls.lastName"
        />
      </div>
      <div class="input-wrapper">
        <label class="field-name-label">
          {{ 'dialogs.profile.firstname' | translate }}
        </label>
        <input
          name="name"
          type="text"
          [ngClass]="{
            '!bg-[#505050] !text-[#A9A9A9]': formControls.firstName.disabled
          }"
          [formControl]="formControls.firstName"
        />
      </div>
      <!-- </div> -->
    </div>
    <div class="right-form">
      <div class="input-wrapper">
        <label class="field-name-label">
          {{ 'dialogs.profile.email' | translate }}
        </label>
        <input
          name="email"
          type="email"
          [ngClass]="{
            '!bg-[#505050] !text-[#A9A9A9]': formControls.email.disabled
          }"
          [formControl]="formControls.email"
        />
      </div>
      <div class="input-wrapper">
        <label class="field-name-label">
          {{ 'dialogs.profile.phone-number' | translate }}
        </label>
        <input
          name="phone"
          type="text"
          [ngClass]="{
            '!bg-[#505050] !text-[#A9A9A9]': formControls.phoneNumber.disabled
          }"
          [formControl]="formControls.phoneNumber"
        />
      </div>
      <dfarm-language-selector [isWhite]="true"></dfarm-language-selector>
    </div>
  </form>
  <div class="buttons">
    <ng-container *ngIf="!isEditing">
      <dfarm-button
        (click)="close()"
        [darkGreenButton]="true">
        {{ 'dialogs.profile.close' | translate }}
      </dfarm-button>
      <dfarm-button
        (click)="editForm(true)"
        [lightGreenButton]="true">
        {{ 'dialogs.profile.edit' | translate }}
      </dfarm-button>
    </ng-container>
    <ng-container *ngIf="isEditing">
      <dfarm-button
        (click)="close()"
        [darkGreenButton]="true">
        {{ 'dialogs.profile.cancel' | translate }}
      </dfarm-button>
      <dfarm-button
        (click)="save()"
        [lightGreenButton]="true">
        {{ 'dialogs.profile.save' | translate }}
      </dfarm-button>
    </ng-container>
  </div>
</div>
