<div class="flex flex-col justify-between h-full">
  <div
    class="text-white text-center h-full flex flex-col items-center justify-center gap-2"
  >
    <div *ngIf="remainingHectare < 0" class="flex justify-center">
      <img
        ngSrc="assets/utility-icons/warning.svg"
        alt="Warning"
        class="w-16 tabletHorizontal:w-1/2"
        height="16" width="16"/>
    </div>
    <div>
      {{ 'dashboard-page.add-field-panel.select-the-points' | translate }}
    </div>
    <div class="text-sm">
      <ng-container *ngIf="remainingHectare >= 0; else noRemainingHectare">
        <div>
          {{ 'dashboard-page.add-field-panel.available-area' | translate }}
          {{ (remainingHectare | number : '1.0-2') + ' Ha' }}
        </div>
        <div>
          {{ 'dashboard-page.add-field-panel.size-of-new-field' | translate }}
          {{ (areaSize | number : '1.0-2') + ' Ha' }}
        </div>
      </ng-container>
      <ng-template #noRemainingHectare>
        {{ 'dashboard-page.add-field-panel.no-remaining-hectare' | translate }}
      </ng-template>
    </div>
  </div>

  <div *ngIf="designating" class="button-wrapper">
    <dfarm-button
      class="w-full"
      (click)="finishFieldDesignation()"
      [lightGreenButton]="true">
      {{ 'dashboard-page.add-field-panel.end-of-selection' | translate }}
    </dfarm-button>
  </div>
  <div *ngIf="!designating" class="button-wrapper gap-6">
    <dfarm-button
      class="flex-1"
      (click)="resetFieldDesignation()"
      [darkGreenButton]="true">
      {{ 'dashboard-page.add-field-panel.retry' | translate }}
    </dfarm-button>
    <dfarm-button
      class="flex-1"
      (click)="next()"
      [disabled]="remainingHectare < 0"
      [lightGreenButton]="true">
      {{ 'dashboard-page.add-field-panel.next' | translate }}
    </dfarm-button>
  </div>
</div>
