<dfarm-panel
  class="parent-panel"
  [isVisible]="panelIsOpen"
  [isLeftSide]="false"
  [activePane]="activePane"
  [isSlideable]="true"
  [isClosable]="true"
  [isSmallPanel]="true"
  *ngIf="!isLoading">
  <div pane1 class="child-panel">
    <div class="title text-xl text-center">
      {{ 'dashboard-page.add-field-panel.add-field-title' | translate }}
    </div>
    <div class="title text-lg text-center">
      {{ 'dashboard-page.add-field-panel.available-area' | translate }}
      {{ remainingHectare }} Ha
    </div>
    <div
      class="p-4 pt-0 h-[calc(100%-3*1rem-28px-28px)] w-full method-button-wrapper desktop:flex-col">
      <div>
        <button
          (click)="selectAddFieldMethod(methodsEnum.map)"
          class="hover:bg-darkGreen"
          [matTooltip]="
            getTranslationTitle('dashboard-page.add-field-panel.map')
          ">
          <div class="icon">
            <img
              src="../../../../../assets/add-field-icons/map.svg"
              alt="map" />
          </div>
          <div class="sub-title">
            {{ 'dashboard-page.add-field-panel.manual' | translate }}
          </div>
        </button>
      </div>
      <div
        [matTooltipDisabled]="!isDesktop"
        [matTooltip]="
          userRole === RoleTypesEnum.Free
            ? getTranslationTitle(
                'dashboard-page.add-field-panel.only-higher-role'
              )
            : isDesktop
            ? getTranslationTitle(
                'dashboard-page.add-field-panel.only-tablet-or-phone'
              )
            : null
        ">
        <button
          (click)="selectAddFieldMethod(methodsEnum.gps)"
          [disabled]="isDesktop || onlyFreeUser"
          class="hover:bg-darkGreen"
          [ngClass]="{
            '!saturate-50 hover:bg-exitButton !cursor-not-allowed opacity-50':
              isDesktop || onlyFreeUser
          }"
          [matTooltipDisabled]="isDesktop"
          [matTooltip]="
            getTranslationTitle('dashboard-page.add-field-panel.gps')
          ">
          <div class="icon">
            <img
              src="../../../../../assets/add-field-icons/compass.svg"
              alt="GPS" />
          </div>
          <div class="sub-title">
            {{ 'dashboard-page.add-field-panel.based-on-gps' | translate }}
          </div>
        </button>
      </div>
      <!-- <button (click)="selectAddFieldMethod(methodsEnum.kml)"
        class="hover:bg-darkGreen">
        <div class="icon">
          <img
            src="../../../../../assets/add-field-icons/file.svg"
            alt="file"
          />
        </div>
        <div class="sub-title">KML fájl importálás</div>
      </button> -->
    </div>
  </div>
  <div pane2 class="child-panel">
    <img
      class="back-icon ml-4"
      src="../../../../../assets/field-inspection-icons/arrow.svg"
      (click)="clickBackToPage1()"
      alt="back" />
    <div class="title text-xl" *ngIf="selectedMethod === methodsEnum.map">
      {{ 'dashboard-page.add-field-panel.map' | translate }}
    </div>
    <div class="title text-xl" *ngIf="selectedMethod === methodsEnum.gps">
      {{ 'dashboard-page.add-field-panel.gps' | translate }}
    </div>
    <div class="p-4 pt-0 h-[calc(100%-2*1rem-28px)] w-full">
      <dfarm-add-field-with-map *ngIf="selectedMethod === methodsEnum.map">
      </dfarm-add-field-with-map>
      <dfarm-add-field-with-gps
        *ngIf="selectedMethod === methodsEnum.gps"
        [startGPSFieldDesignationDisabled]="
          startGPSFieldDesignationDisabled
        "></dfarm-add-field-with-gps>
      <!-- <dfarm-add-field-with-kml
        *ngIf="selectedMethod === methodsEnum.kml"
      ></dfarm-add-field-with-kml> -->
    </div>
  </div>
  <div pane3 class="child-panel">
    <img
      class="back-icon ml-4"
      src="../../../../../assets/field-inspection-icons/arrow.svg"
      (click)="clickBackToPage2()"
      alt="back" />
    <div class="title text-xl" *ngIf="selectedMethod === methodsEnum.map">
      {{ 'dashboard-page.add-field-panel.map' | translate }}
    </div>
    <div class="title text-xl" *ngIf="selectedMethod === methodsEnum.gps">
      {{ 'dashboard-page.add-field-panel.based-on-gps' | translate }}
    </div>
    <div class="p-4 pt-0 h-[calc(100%-2*1rem-28px)] w-full">
      <div class="flex flex-col justify-between h-full items-center">
        <div class="input-wrapper">
          <!-- <label class="field-name-label"> Terület neve </label> -->
          <input
            name="fieldName"
            type="text"
            placeholder="{{
              'dashboard-page.add-field-panel.field-name' | translate
            }}"
            [ngClass]="{
              'error-input':
                (fieldName.touched && fieldName.invalid) ||
                (isSubmitted && fieldName.invalid)
            }"
            [formControl]="fieldName" />
        </div>
        <div class="w-full">
          <dfarm-button
            class="w-full"
            (click)="saveField()"
            [lightGreenButton]="true">
            {{ 'dashboard-page.add-field-panel.save' | translate }}
          </dfarm-button>
        </div>
      </div>
    </div>
  </div>
  <div pane4 class="child-panel">
    <div
      class="p-4 h-full w-full flex flex-col justify-between items-center tabletHorizontal:justify-center">
      <img
        class="congratulations-img"
        src="../../../../../assets/utility-icons/checked.svg"
        alt="congratulation" />
      <div class="congratulations-message">
        {{ 'dashboard-page.add-field-panel.congratulations' | translate }}
      </div>
      <div class="congratulations-message">
        {{ 'dashboard-page.add-field-panel.successful' | translate }}
      </div>
      <dfarm-button
        class="w-full tabletHorizontal:w-1/2"
        (click)="resetAddFieldPanel()"
        [lightGreenButton]="true">
        {{ 'dashboard-page.add-field-panel.new-field' | translate }}
      </dfarm-button>
    </div>
  </div>
</dfarm-panel>
