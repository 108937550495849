import { TranslateService } from '@ngx-translate/core';
import { LanguageTypes } from '../models/languages.model';

export const changeTranslate = (
  translateService: TranslateService,
  language: LanguageTypes
): void => {
  localStorage.setItem('language', language);
  translateService.use(language);
  window.location.reload();
};
