<dfarm-panel
  *ngIf="!isLoading"
  [isLeftSide]="true"
  [isVisible]="panelIsOpen"
  [wrapperIsBlock]="true"
  class="dfarm-menu"
>
  <div class="list-wrapper">
    <ul class="main-list">
      <li
        class="main-list-item"
        customClassName="open-menu-list-item"
        dfarmExpandMenu
      >
        <div class="title-wrapper">
          <img
            class="arrow-icon"
            src="../../../../../assets/menu-icons/arrowIcon.svg"
          />
          {{ 'menu.fields-manager' | translate }}
        </div>
        <ul class="sub-list">
          <li>
            <div
              (click)="showFieldInspectorPanel(); $event.stopPropagation()"
              class="subtitle-wrapper"
            >
              <img src="../../../../../assets/menu-icons/overviewIcon.svg"/>
              {{ 'menu.overview' | translate }}
            </div>
          </li>
          <li>
            <div
              (click)="showAddFieldPanel(); $event.stopPropagation()"
              class="subtitle-wrapper"
            >
              <img src="../../../../../assets/menu-icons/addFieldIcon.svg"/>
              {{ 'menu.add-field' | translate }}
            </div>
          </li>
        </ul>
      </li>
      <li
        *ngIf="hasBasicPermission || hasSensorPermission"
        class="main-list-item"
        customClassName="open-menu-list-item"
        dfarmExpandMenu
      >
        <div class="title-wrapper">
          <img
            class="arrow-icon"
            src="../../../../../assets/menu-icons/arrowIcon.svg"
          />
          {{ 'menu.reports' | translate }}
        </div>
        <ul class="sub-list">
          <li *ngIf="hasBasicPermission">
            <div
              (click)="showDataViewerPanel(); $event.stopPropagation()"
              class="subtitle-wrapper"
            >
              <img
                src="../../../../../assets/menu-icons/inspectFieldIcon.svg"
              />
              {{ 'menu.weather-data' | translate }}
            </div>
          </li>
          <li *ngIf="hasBasicPermission">
            <div
              (click)="showSatelliteViewerPanel(); $event.stopPropagation()"
              class="subtitle-wrapper"
            >
              <img
                src="../../../../../assets/menu-icons/inspectFieldIcon.svg"
              />
              {{ 'menu.satellite-data' | translate }}
            </div>
          </li>
          <li *ngIf="hasSensorPermission">
            <div
              (click)="showSensorViewerPanel(); $event.stopPropagation()"
              class="subtitle-wrapper"
            >
              <img
                src="../../../../../assets/menu-icons/inspectFieldIcon.svg"
              />
              {{ 'menu.sensor-data' | translate }}
            </div>
          </li>
        </ul>
      </li>
      <li
        *ngIf="!isProduction"
        class="main-list-item"
        customClassName="open-menu-list-item"
        dfarmExpandMenu
      >
        <div class="title-wrapper">
          <img
            class="arrow-icon"
            src="../../../../../assets/menu-icons/arrowIcon.svg"
          />
          Vízhasználati jelentések
        </div>
        <ul class="sub-list">
          <li>
            <div (click)="showWaterscopeItemListPanel(); $event.stopPropagation()" class="subtitle-wrapper">
              <img src="../../../../../assets/menu-icons/inspectFieldIcon.svg"/>
              Eszközök listája
            </div>
          </li>
          <li>
            <div (click)="showWaterscopeTablePanel(); $event.stopPropagation()" class="subtitle-wrapper">
              <img src="../../../../../assets/menu-icons/inspectFieldIcon.svg"/>
              Táblázat
            </div>
          </li>
          <li>
            <div (click)="showWaterscopeChartPanel(); $event.stopPropagation()" class="subtitle-wrapper">
              <img src="../../../../../assets/menu-icons/inspectFieldIcon.svg"/>
              Grafikon
            </div>
          </li>
        </ul>
      </li>
      <li
        class="main-list-item"
        customClassName="open-menu-list-item"
        dfarmExpandMenu
      >
        <div class="title-wrapper">
          <img
            class="arrow-icon"
            src="../../../../../assets/menu-icons/arrowIcon.svg"
          />
          {{ 'menu.help' | translate }}
        </div>
        <ul class="sub-list">
          <!-- <li>
            <div class="subtitle-wrapper">
              <img src="../../../../../assets/menu-icons/supportIcon.svg" />
              Támogatás
            </div>
          </li>
          <li>
            <div class="subtitle-wrapper">
              <img src="../../../../../assets/menu-icons/tutorialIcon.svg" />
              Útmutató
            </div>
          </li>
          <li>
            <div class="subtitle-wrapper">
              <img
                src="../../../../../assets/menu-icons/documentationIcon.svg"
              />
              Dokumentáció
            </div>
          </li> -->
          <li>
            <div (click)="navigateToDfarm()" class="subtitle-wrapper">
              <img src="../../../../../assets/menu-icons/contactIcon.svg"/>
              {{ 'menu.contact' | translate }}
            </div>
          </li>
        </ul>
      </li>
    </ul>
    <!-- TODO: Ide majd a zászlók jönnek -->
    <!-- <div class="flags">flags</div> -->
  </div>
</dfarm-panel>
