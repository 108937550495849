import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { HttpClient } from '@angular/common/http';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { SharedModule } from 'src/app/shared/shared.module';
import { HttpLoaderFactory } from 'src/app/shared/utils/translate.factory';
import { environment } from 'src/environments/environment';
import { LoginRoutingModule } from './login-routing.module';
import { ActivateProfileComponent } from './pages/activate-profile/activate-profile.component';
import { AszfComponent } from './pages/aszf/aszf.component';
import { ForgotPasswordComponent } from './pages/forgot-password/forgot-password.component';
import { LoginComponent } from './pages/login/login.component';
import { RegisterComponent } from './pages/register/register.component';
import { LanguageSelectorComponent } from '../../shared/components/language-selector/language-selector.component';
import { ButtonComponent } from '../../shared/components/button/button.component';

@NgModule({
  declarations: [
    LoginComponent,
    RegisterComponent,
    ForgotPasswordComponent,
    ActivateProfileComponent,
    AszfComponent,
  ],
  imports: [
    CommonModule,
    SharedModule,
    LoginRoutingModule,
    TranslateModule.forChild({
      defaultLanguage:
        localStorage.getItem('translate') ?? environment.defaultLanguage,
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      },
    }),
    LanguageSelectorComponent,
    ButtonComponent,
  ],
})
export class LoginModule {}
