import { Component, computed, OnDestroy, OnInit, Signal } from '@angular/core';
import * as MapboxDraw from '@mapbox/mapbox-gl-draw';
import { ofType } from '@ngrx/effects';
import { ActionsSubject, select, Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import * as turf from '@turf/turf';
import * as mapboxgl from 'mapbox-gl';
import { DeviceDetectorService } from 'ngx-device-detector';
import { combineLatest, interval, Subscription } from 'rxjs';
import { map, take, takeWhile } from 'rxjs/operators';
import { CorePartialState } from 'src/app/core/state/core.reducer';
import { AuthService } from 'src/app/pages/login/auth.service';
import { DeviceTypeEnum } from 'src/app/shared/enums/device-type.enum';
import { RoleTypesEnum } from 'src/app/shared/enums/role-type.enum';
import { SnackbarService } from 'src/app/shared/services/snackbar.service';
import { environment } from '../../../../../environments/environment';
import * as CoreActions from '../../../../core/state/core.actions';
import {
  CoordinateViewModel,
  FieldDetailsViewModel,
  FieldsViewModel,
} from '../../models/field.model';
import { Location, SensorDataLocations } from '../../models/sensor-data.model';
import { DashboardService } from '../../services/dashboard.service';
import * as DashboardActions from '../../state/dashboard.actions';
import {
  DashboardPartialState,
  DrawingOnMapFieldDesignationState,
  GPSFieldDesignationState,
  SensorDataTab,
} from '../../state/dashboard.reducer';
import * as DashboardQueries from '../../state/dashboard.selectors';
import * as CoreQueries from '../../../../core/state/core.selectors';
import { toSignal } from '@angular/core/rxjs-interop';
import { WateringDeviceData } from '../../../../shared/models/watering-device.model';

@Component({
  selector: 'dfarm-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss'],
})
export class DashboardComponent implements OnInit, OnDestroy {
  subs: Subscription[] = [];
  map: mapboxgl.Map;
  satelliteStreetsStyle = 'mapbox://styles/mapbox/satellite-streets-v11';
  lat: number = 47.497913;
  lng: number = 19.040236;
  draw: MapboxDraw;
  geolocate: mapboxgl.GeolocateControl;
  mapOffset = [];

  fields: FieldDetailsViewModel[] = [];
  selectedField: FieldDetailsViewModel;
  selectedFieldIndex: number;

  satelliteImageSource: string = 'satellite-image-source';
  satelliteImageLayer: string = 'satellite-image-layer';

  markers: mapboxgl.Marker[] = [];
  gpsPermissionDenied: boolean = false;
  startGPSFieldDesignationDisabled: boolean = true;

  watchPositionHandlerId: any;
  gpsFieldDesignationCoordinates = [[]];
  designatedFieldId: string = 'designatedFieldId';
  designatedFieldCoordinates = [];

  drawingOnMapDesignatedFieldFeatureId: string;
  drawingOnMapFieldDesignationState: DrawingOnMapFieldDesignationState;
  gpsDesignatedFieldFeatureId: string = 'gpsDesignatedFieldId';
  gpsFieldDesignationState: GPSFieldDesignationState;

  sensorDataLocations: SensorDataLocations;
  selectedSensorDataLocation: Location;
  selectedDeviceType: DeviceTypeEnum;
  activeSensorDataTab: SensorDataTab;

  wateringDeviceLocation: Location;
  waterscopeItemListPanelIsOpen: Signal<boolean> = toSignal(
    this.coreStore.pipe(select(CoreQueries.getWaterscopeItemListPanelIsOpen)),
  );
  selectedWateringDevice: Signal<WateringDeviceData> = toSignal(
    this.dashboardStore.pipe(
      select(DashboardQueries.getSelectedWateringDevice),
    ),
  );
  waterscopeMarkerIsVisible = computed(() => {
    return (
      this.waterscopeItemListPanelIsOpen() && !!this.selectedWateringDevice()
    );
  });

  GREEN_FIELD_COLOR: string = '#04E824';
  GREY_FIELD_COLOR: string = '#7F7F7F';
  assetsUrl: string = '../../../../../assets/';

  constructor(
    private readonly coreStore: Store<CorePartialState>,
    private readonly dashboardStore: Store<DashboardPartialState>,
    private readonly dashboardService: DashboardService,
    private readonly actionsSubject: ActionsSubject,
    private readonly authService: AuthService,
    private readonly snackbarService: SnackbarService,
    private readonly translateService: TranslateService,
    private readonly deviceDetectorService: DeviceDetectorService,
  ) {}

  ngOnInit(): void {
    this.subs.push(
      this.authService.userRoles.pipe(take(1)).subscribe(roles => {
        if (
          roles.some(x => x === RoleTypesEnum.Basic) ||
          roles.some(x => x === RoleTypesEnum.Trial)
        ) {
          this.coreStore.dispatch(CoreActions.openDataViewerPanel());
        } else {
          this.coreStore.dispatch(CoreActions.openFieldInspectorPanel());
        }
      }),
    );
    this.map = new mapboxgl.Map({
      accessToken: environment.mapbox.accessToken,
      container: 'map',
      style: this.satelliteStreetsStyle,
      zoom: 15,
      pitch: 45,
      center: [this.lng, this.lat], // starting position
    });

    this.draw = new MapboxDraw({
      displayControlsDefault: false,
      controls: {
        trash: false,
      },
    });
    this.map.addControl(this.draw, 'top-left');

    this.map.on('load', () => {
      this.dashboardService.getFields().subscribe(res => {
        this.fields = res.lands;
        this.fields.forEach(field => {
          const coordinates = this.transformFieldCoordinatesToCoordinateArray(
            field.coordinates,
          );
          this.addFieldToMap(field.id, coordinates, false);
        });

        this.selectField(this.fields[0]);

        this.dashboardStore.dispatch(
          DashboardActions.setAreaSizeLimit({
            areaSizeLimit: res.areaSizeLimit,
          }),
        );
      });
    });

    this.map.on('draw.create', event => {
      const data = this.draw.getAll();
      this.updateSelectedPolygon(data);
    });

    this.map.on('draw.delete', event => {
      const data = this.draw.getAll();
      this.updateSelectedPolygon(data);
    });

    this.map.on('draw.update', event => {
      const data = this.draw.getAll();
      this.updateSelectedPolygon(data);

      // this.gpsFieldDesignationCoordinates =
      //   data.features[0].geometry.coordinates;
    });

    this.map.on('draw.selectionchange', event => {
      if (
        this.gpsFieldDesignationState === GPSFieldDesignationState.DESIGNATING
      ) {
        this.draw.changeMode(this.draw.modes.SIMPLE_SELECT, {
          featureIds: [this.gpsDesignatedFieldFeatureId],
        });
      }
    });
    this.map.on('draw.modechange', event => {
      if (
        this.drawingOnMapFieldDesignationState ===
        DrawingOnMapFieldDesignationState.DESIGNATED
      ) {
        this.draw.changeMode(this.draw.modes.DIRECT_SELECT, {
          featureId: this.drawingOnMapDesignatedFieldFeatureId,
        });
      } else if (
        this.gpsFieldDesignationState === GPSFieldDesignationState.DESIGNATED
      ) {
        this.draw.changeMode(this.draw.modes.DIRECT_SELECT, {
          featureId: this.gpsDesignatedFieldFeatureId,
        });
      }
    });

    // Add map controls
    this.map.addControl(new mapboxgl.NavigationControl(), 'top-left');
    this.geolocate = new mapboxgl.GeolocateControl({
      positionOptions: {
        enableHighAccuracy: true,
      },
      // When active the map will receive updates to the device's location as it changes.
      trackUserLocation: true,
      showUserLocation: true,
      showAccuracyCircle: true,
      showUserHeading: true,
    });
    this.map.addControl(this.geolocate, 'top-left');

    this.mapOffset = [0, -window.innerHeight * 0.3]; // default portrait
    if (this.deviceDetectorService.orientation === 'landscape') {
      this.mapOffset = [-window.innerWidth * 0.25, 0];
    }

    window.onresize = () => {
      if (window.innerWidth > window.innerHeight) {
        // landscape
        this.mapOffset = [-window.innerWidth * 0.25, 0];
      } else if (window.innerWidth < window.innerHeight) {
        // portrait
        this.mapOffset = [0, -window.innerHeight * 0.3];
      }
      this.map.flyTo({
        center: [this.lng, this.lat],
        offset: [this.mapOffset[0], this.mapOffset[1]],
        speed: 1,
        zoom: 15,
        curve: 0.6,
      });
    };

    this.subs.push(
      this.dashboardStore
        .pipe(select(DashboardQueries.getDrawingOnMapFieldDesignationState))
        .subscribe(drawingOnMapFieldDesignationState => {
          this.drawingOnMapFieldDesignationState =
            drawingOnMapFieldDesignationState;
          if (
            drawingOnMapFieldDesignationState ===
            DrawingOnMapFieldDesignationState.UNSELECTED
          ) {
            this.drawingOnMapFieldDesignationUnselectedHandler();
          } else if (
            drawingOnMapFieldDesignationState ===
            DrawingOnMapFieldDesignationState.DESIGNATING
          ) {
            this.drawingOnMapFieldDesignationDesignatingHandler();
          } else if (
            drawingOnMapFieldDesignationState ===
            DrawingOnMapFieldDesignationState.DESIGNATED
          ) {
            this.drawingOnMapFieldDesignationDesignatedHandler();
          } else if (
            drawingOnMapFieldDesignationState ===
            DrawingOnMapFieldDesignationState.NAMING_FIELD
          ) {
            this.drawingOnMapFieldDesignationNamingFieldHandler();
          }
        }),
      // GET GPS Field Designation State
      this.dashboardStore
        .pipe(select(DashboardQueries.getGPSFieldDesignationState))
        .subscribe(gpsFieldDesignationState => {
          this.gpsFieldDesignationState = gpsFieldDesignationState;

          if (
            gpsFieldDesignationState === GPSFieldDesignationState.UNSELECTED
          ) {
            this.gpsFieldDesignatioUnselectedHandler();
          } else if (
            gpsFieldDesignationState === GPSFieldDesignationState.INIT
          ) {
            this.gpsFieldDesignationInitHandler();
          } else if (
            gpsFieldDesignationState === GPSFieldDesignationState.DESIGNATING
          ) {
            this.gpsFieldDesignationDesignatingHandler();
          } else if (
            gpsFieldDesignationState === GPSFieldDesignationState.DESIGNATED
          ) {
            this.gpsFieldDesignationDesignatedHandler();
          } else if (
            gpsFieldDesignationState === GPSFieldDesignationState.NAMING_FIELD
          ) {
            this.gpsFieldDesignationNamingFieldHandler();
          }
        }),
      this.dashboardStore
        .pipe(select(DashboardQueries.getSelectedCoordinates))
        .subscribe(
          selectedCoordinates =>
            (this.designatedFieldCoordinates = selectedCoordinates),
        ),
      this.actionsSubject
        .pipe(ofType(DashboardActions.updateDashboardContent))
        .subscribe(() => {
          this.selectedField = undefined;
          this.selectedFieldIndex = undefined;
          this.fields.forEach((field: FieldDetailsViewModel) => {
            this.removeFieldFromMap(field.id);
          });

          this.dashboardService
            .getFields()
            .subscribe((res: FieldsViewModel) => {
              this.fields = res.lands;
              this.fields.forEach((field: FieldDetailsViewModel) => {
                const coordinates =
                  this.transformFieldCoordinatesToCoordinateArray(
                    field.coordinates,
                  );
                this.addFieldToMap(field.id, coordinates, false);
              });

              this.selectField(this.fields[this.fields.length - 1]);
              this.map.easeTo({ pitch: 45, duration: 1000 });
            });
        }),
      this.dashboardStore
        .pipe(select(DashboardQueries.getSelectedSatelliteImageURL))
        .subscribe((selectedSatelliteImageURL: string) => {
          this.removeSatelliteImageFromMap();

          if (selectedSatelliteImageURL !== null) {
            this.addSatelliteImageToMap(selectedSatelliteImageURL);
          }
        }),
      this.dashboardStore
        .pipe(select(DashboardQueries.getSensorDataLocations))
        .subscribe((sensorDataLocations: SensorDataLocations) => {
          this.unselectSensorDataLocationMarker();
          this.unselectSensorDataLocation();
          this.clearMarkers();

          if (sensorDataLocations === undefined) {
            return;
          }

          this.sensorDataLocations = sensorDataLocations;

          // Add gateways to map
          this.sensorDataLocations.gatewayDataLocations.forEach(
            (gatewayDataLocation: Location) => {
              this.putMarkerToMap(
                gatewayDataLocation,
                DeviceTypeEnum.Gateway,
                () => {
                  if (this.activeSensorDataTab === SensorDataTab.DETAILS) {
                    this.unselectSensorDataLocationMarker();
                    this.unselectSensorDataLocation();
                    this.selectSensorDataLocation(
                      gatewayDataLocation,
                      DeviceTypeEnum.Gateway,
                    );
                    this.selectSensorDataLocationMarker(
                      gatewayDataLocation,
                      DeviceTypeEnum.Gateway,
                    );
                  }
                },
              );
            },
          );

          // Add nodes to map
          this.sensorDataLocations.nodeDataLocations.forEach(
            (nodeDataLocation: Location) => {
              this.putMarkerToMap(nodeDataLocation, DeviceTypeEnum.Node, () => {
                if (this.activeSensorDataTab === SensorDataTab.DETAILS) {
                  this.unselectSensorDataLocationMarker();
                  this.unselectSensorDataLocation();
                  this.selectSensorDataLocation(
                    nodeDataLocation,
                    DeviceTypeEnum.Node,
                  );
                  this.selectSensorDataLocationMarker(
                    nodeDataLocation,
                    DeviceTypeEnum.Node,
                  );
                }
              });
            },
          );

          if (this.activeSensorDataTab === SensorDataTab.DETAILS) {
            this.selectFirstSensorDataLocation(this.sensorDataLocations);
          }
        }),

      this.dashboardStore
        .pipe(select(DashboardQueries.getSelectedSensorDataTab))
        .subscribe((sensorDataTab: SensorDataTab) => {
          this.activeSensorDataTab = sensorDataTab;

          if (this.activeSensorDataTab === SensorDataTab.OVERVIEW) {
            this.unselectSensorDataLocationMarker();
          } else if (this.activeSensorDataTab === SensorDataTab.DETAILS) {
            if (this.selectedSensorDataLocation === undefined) {
              this.selectFirstSensorDataLocation(this.sensorDataLocations);
            } else {
              this.selectSensorDataLocationMarker(
                this.selectedSensorDataLocation,
                this.selectedDeviceType,
              );
            }
          }
        }),
    );

    combineLatest([
      this.dashboardStore.pipe(
        select(DashboardQueries.getSelectedWateringDevice),
      ),
      this.coreStore.pipe(select(CoreQueries.getWaterscopeItemListPanelIsOpen)),
    ]).subscribe(([wateringDevice, waterscopeItemListPanelIsOpen]) => {
      // Ideiglenes megoldás
      this.clearMarkers();

      if (!waterscopeItemListPanelIsOpen || !this.selectedWateringDevice()) {
        return;
      }

      this._flyToFieldWithCoordinates(20.230572102982904, 46.92156942209745);
      this.subs.forEach(sub => sub.unsubscribe());
      this.subs.push(
        interval(1000)
          .pipe(
            map(index => index),
            takeWhile(() => this.waterscopeMarkerIsVisible()),
          )
          .subscribe(index => {
            console.log('INDEX', index);
            this.unselectSensorDataLocationMarker();
            this.unselectSensorDataLocation();
            this.clearMarkers();

            if (!wateringDevice) {
              return;
            }

            switch (index % 5) {
              case 0:
                this.wateringDeviceLocation = {
                  id: wateringDevice.id,
                  coordinate: {
                    latitude: 46.92156942209745,
                    longitude: 20.230572102982904,
                  },
                };
                break;
              case 1:
                this.wateringDeviceLocation = {
                  id: wateringDevice.id,
                  coordinate: {
                    latitude: 46.91970162779899,
                    longitude: 20.229679948163476,
                  },
                };
                break;
              case 2:
                this.wateringDeviceLocation = {
                  id: wateringDevice.id,
                  coordinate: {
                    latitude: 46.91842990079431,
                    longitude: 20.229059318723888,
                  },
                };
                break;
              case 3:
                this.wateringDeviceLocation = {
                  id: wateringDevice.id,
                  coordinate: {
                    latitude: 46.916151314435595,
                    longitude: 20.227934427864607,
                  },
                };
                break;
              case 4:
                this.wateringDeviceLocation = {
                  id: wateringDevice.id,
                  coordinate: {
                    latitude: 46.90844086374832,
                    longitude: 20.22412166912824,
                  },
                };
                break;
            }

            // this.wateringDeviceLocation = {
            //   id: wateringDevice.id,
            //   coordinate: { latitude: 47.497913, longitude: 19.040236 },
            // };

            // Add Watering Device to map
            this.putMarkerToMap(
              this.wateringDeviceLocation,
              DeviceTypeEnum.WateringDevice,
              () => {
                // if (this.activeSensorDataTab === SensorDataTab.DETAILS) {
                //   this.unselectSensorDataLocationMarker();
                //   this.unselectSensorDataLocation();
                //   this.selectSensorDataLocation(
                //     this.wateringDeviceLocation,
                //     DeviceTypeEnum.Gateway,
                //   );
                //   this.selectSensorDataLocationMarker(
                //     this.wateringDeviceLocation,
                //     DeviceTypeEnum.Gateway,
                //   );
                // }
              },
            );

            // if (this.activeSensorDataTab === SensorDataTab.DETAILS) {
            //   this.selectFirstSensorDataLocation(this.sensorDataLocations);
            // }
          }),
      );
    });
  }

  selectField(selectedField: FieldDetailsViewModel): void {
    if (this.selectedField !== undefined) {
      this.removeFieldPolygonLayerFromMap(this.selectedField.id);
      this.addFieldPolygonLayerToMap(this.selectedField.id, false);
    }

    this.selectedField = selectedField;
    this.selectedFieldIndex = this.fields.findIndex(
      (field: FieldDetailsViewModel) => field.id === this.selectedField.id,
    );

    if (!this.selectedField) {
      return;
    }
    this.removeFieldPolygonLayerFromMap(this.selectedField.id);
    this.addFieldPolygonLayerToMap(this.selectedField.id, true);

    const coordinates = this.transformFieldCoordinatesToCoordinateArray(
      this.selectedField.coordinates,
    );
    const polygonCoordinates = this.createPolygonCoordinates(coordinates);
    const center = this.calculateCenterOfField(polygonCoordinates);

    this.flyToField(center);

    this.dashboardStore.dispatch(
      DashboardActions.setSelectedField({
        field: this.selectedField,
      }),
    );
  }

  selectNextField(): void {
    const selectedFieldIndex: number =
      this.selectedFieldIndex + 1 === this.fields.length
        ? 0
        : this.selectedFieldIndex + 1;

    this.selectFieldByIndex(selectedFieldIndex);
  }

  selectPreviousField(): void {
    const selectedFieldIndex: number =
      this.selectedFieldIndex === 0
        ? this.fields.length - 1
        : this.selectedFieldIndex - 1;

    this.selectFieldByIndex(selectedFieldIndex);
  }

  selectFieldByIndex(selectedFieldIndex: number): void {
    if (this.fields.length === 0) {
      return;
    }

    const selectedField: FieldDetailsViewModel =
      this.fields[selectedFieldIndex];

    this.selectField(selectedField);
  }

  removeSatelliteImageFromMap(): void {
    if (this.map.getLayer(this.satelliteImageLayer)) {
      this.map.removeLayer(this.satelliteImageLayer);
      this.map.removeSource(this.satelliteImageSource);
    }
  }

  ngOnDestroy(): void {
    this.subs.forEach(sub => sub.unsubscribe());
  }

  private transformFieldCoordinatesToCoordinateArray(
    fieldCoordinates: CoordinateViewModel[],
  ): any[][] {
    const coordinates = [[]];
    fieldCoordinates.forEach((coordinateViewModel: CoordinateViewModel) => {
      coordinates[0].push([
        coordinateViewModel.longitude,
        coordinateViewModel.latitude,
      ]);
    });

    return coordinates;
  }

  private transformCoordinateArrayToFieldCoordinates(
    coordinateArray: any[][],
  ): CoordinateViewModel[] {
    const coordinates: CoordinateViewModel[] = [];
    coordinateArray[0].forEach(coordinate => {
      const payload: CoordinateViewModel = {
        // coordinate[0] a longitude
        // coordinate[1] a latitude;
        longitude: coordinate[0],
        latitude: coordinate[1],
      };
      coordinates.push(payload);
    });

    return coordinates;
  }

  private addFieldToMap(
    fieldId: string,
    coordinates: any[][],
    selected: boolean,
  ): void {
    const polygonCoordinates = this.createPolygonCoordinates(coordinates);

    this.createFieldPolygonSource(fieldId, polygonCoordinates);
    this.addFieldPolygonLayerToMap(fieldId, selected);

    const center = this.calculateCenterOfField(polygonCoordinates);
    const area: number = this.calculateAreaOfField(polygonCoordinates);

    center.properties.areaLabel = `${area} Ha`;

    this.createFieldLabelSource(fieldId, center);
    this.addFieldLabelToMap(fieldId);
  }

  private removeFieldFromMap(fieldId: string): void {
    this.removeFieldPolygonLayerFromMap(fieldId);
    this.deleteFieldPolygonSource(fieldId);

    this.removeFieldLabelFromMap(fieldId);
    this.deleteFieldLabelSource(fieldId);
  }

  private createPolygonCoordinates(coordinates: any[][]): any[][] {
    const polygonCoordinates = [...coordinates];

    // Azért kell hozzáadni az első koordinátát a végére, hogy szépen bekeretezze a polygont
    polygonCoordinates[0].push(coordinates[0][0]);

    return polygonCoordinates;
  }

  private createFieldPolygonSource(
    fieldId: string,
    polygonCoordinates: any[][],
  ): void {
    const fieldSourceId: string = fieldId + '-Source';

    // Add a data source containing GeoJSON data.
    this.map.addSource(fieldSourceId, {
      type: 'geojson',
      data: {
        properties: null,
        type: 'Feature',
        geometry: {
          type: 'Polygon',
          // These coordinates outline selected field.
          coordinates: polygonCoordinates,
        },
      },
    });
  }

  private deleteFieldPolygonSource(fieldId: string): void {
    const fieldSourceId: string = fieldId + '-Source';

    if (this.map.getSource(fieldSourceId)) {
      this.map.removeSource(fieldSourceId);
    }
  }

  private addFieldPolygonLayerToMap(fieldId: string, selected: boolean): void {
    const fieldSourceId: string = fieldId + '-Source';
    const fieldLayer: string = fieldId + '-Layer';
    const fieldOutlineLayer: string = fieldId + '-OutlineLayer';

    const fieldColor = selected
      ? this.GREEN_FIELD_COLOR
      : this.GREY_FIELD_COLOR;

    // Add a new layer to visualize the polygon.
    this.map.addLayer({
      id: fieldLayer,
      type: 'fill',
      source: fieldSourceId, // reference the data source
      layout: {},
      paint: {
        'fill-color': fieldColor, // green color fill
        'fill-opacity': 0.4,
      },
    });

    // Add an outline around the polygon.
    this.map.addLayer({
      id: fieldOutlineLayer,
      type: 'line',
      source: fieldSourceId,
      layout: {},
      paint: {
        'line-color': fieldColor,
        'line-width': 3,
      },
    });
  }

  private removeFieldPolygonLayerFromMap(fieldId: string): void {
    const fieldLayer: string = fieldId + '-Layer';
    const fieldOutlineLayer: string = fieldId + '-OutlineLayer';

    if (this.map.getLayer(fieldLayer)) {
      this.map.removeLayer(fieldLayer);
    }
    if (this.map.getLayer(fieldOutlineLayer)) {
      this.map.removeLayer(fieldOutlineLayer);
    }
  }

  private calculateCenterOfField(polygonCoordinates: any[][]) {
    const center = turf.centroid({
      properties: null,
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        // These coordinates outline selected field.
        coordinates: polygonCoordinates,
      },
    });

    return center;
  }

  private calculateAreaOfField(polygonCoordinates: any[][]) {
    const area: number =
      turf.area({
        properties: null,
        type: 'Feature',
        geometry: {
          type: 'Polygon',
          coordinates: polygonCoordinates,
        },
      }) / 10000;
    const roundedArea = Math.round(area * 100) / 100;

    return roundedArea;
  }

  private createFieldLabelSource(fieldId: string, center: any): void {
    const labelSourceId = fieldId + '-LabelSource';

    this.map.addSource(labelSourceId, {
      type: 'geojson',
      data: center,
    });
  }

  private deleteFieldLabelSource(fieldId: string): void {
    const labelSourceId = fieldId + '-LabelSource';

    if (this.map.getSource(labelSourceId)) {
      this.map.removeSource(labelSourceId);
    }
  }

  private addFieldLabelToMap(fieldId: string): void {
    const labelSourceId: string = fieldId + '-LabelSource';
    const labelLayer: string = fieldId + '-AreaLabelLayer';

    // Add a new layer to visualize the area label.
    this.map.addLayer({
      id: labelLayer,
      type: 'symbol',
      source: labelSourceId, // reference the data source
      layout: {
        'text-field': ['get', 'areaLabel'],
      },
      paint: {
        'text-color': 'white',
      },
    });
  }

  private removeFieldLabelFromMap(fieldId: string): void {
    const labelLayer: string = fieldId + '-AreaLabelLayer';

    if (this.map.getLayer(labelLayer)) {
      this.map.removeLayer(labelLayer);
    }
  }

  private _flyToFieldWithCoordinates(
    longitude: number,
    latitude: number,
  ): void {
    console.log('FLY TO WITH COORDINATES');
    this.map.flyTo({
      center: [longitude, latitude],
      offset: [this.mapOffset[0], this.mapOffset[1]],
      speed: 0.8,
      zoom: 15,
      curve: 0.6,
    });
  }

  private flyToField(center): void {
    this.lng = center.geometry.coordinates[0];
    this.lat = center.geometry.coordinates[1];

    this.map.flyTo({
      center: [this.lng, this.lat],
      offset: [this.mapOffset[0], this.mapOffset[1]],
      speed: 0.8,
      zoom: 15,
      curve: 0.6,
    });
  }

  private updateSelectedPolygon(data): void {
    if (data.features?.length > 0) {
      const coordinates = data.features[0].geometry.coordinates;
      const area = this.calculateAreaOfField(coordinates);

      this.dashboardStore.dispatch(
        DashboardActions.setSelectedCoordinatesArea({
          area,
        }),
      );

      const transformedCoordinates =
        this.transformCoordinateArrayToFieldCoordinates(coordinates);
      transformedCoordinates.splice(transformedCoordinates.length - 1, 1);

      this.dashboardStore.dispatch(
        DashboardActions.addCoordinatesToStore({
          coordinates: transformedCoordinates,
        }),
      );
    }
  }

  private addSatelliteImageToMap(selectedSatelliteImageUrl: string) {
    let minLongitude: number = this.selectedField.coordinates[0].longitude;
    let maxLongitude: number = this.selectedField.coordinates[0].longitude;
    let minLatitude: number = this.selectedField.coordinates[0].latitude;
    let maxLatitude: number = this.selectedField.coordinates[0].latitude;

    this.selectedField.coordinates.forEach(
      (coordinate: CoordinateViewModel) => {
        if (coordinate.longitude < minLongitude) {
          minLongitude = coordinate.longitude;
        }
        if (coordinate.longitude > maxLongitude) {
          maxLongitude = coordinate.longitude;
        }
        if (coordinate.latitude < minLatitude) {
          minLatitude = coordinate.latitude;
        }
        if (coordinate.latitude > maxLatitude) {
          maxLatitude = coordinate.latitude;
        }

        // const el = document.createElement("div");
        // const width = 100;
        // const height = 160;
        // el.className = "marker";
        // el.style.backgroundImage = `url("../../../../../assets/dfarm-marker.png")`;
        // el.style.width = `${width}px`;
        // el.style.height = `${height}px`;
        // el.style.backgroundSize = "100%";

        // const marker = new mapboxgl.Marker(el)
        // // const marker = new mapboxgl.Marker()
        // 	.setLngLat([coordinate.longitude, coordinate.latitude])
        //   .setOffset([0, -80])
        // 	.addTo(this.map); // add the marker to the map
      },
    );

    const imageCoordinates = [
      [minLongitude, maxLatitude],
      [maxLongitude, maxLatitude],
      [maxLongitude, minLatitude],
      [minLongitude, minLatitude],
    ];

    const splittedSelectedSatelliteImageUrl: string[] =
      selectedSatelliteImageUrl.split('://');
    const url: string =
      'https://' + splittedSelectedSatelliteImageUrl[1] + '&paletteid=4';

    this.map.addSource(this.satelliteImageSource, {
      type: 'image',
      url,
      coordinates: imageCoordinates,
    });
    this.map.addLayer({
      id: this.satelliteImageLayer,
      type: 'raster',
      source: this.satelliteImageSource,
      paint: {
        'raster-fade-duration': 0,
      },
    });
  }

  private drawingOnMapFieldDesignationUnselectedHandler(): void {
    if (this.selectedField !== undefined) {
      this.removeFieldPolygonLayerFromMap(this.selectedField.id);
      this.addFieldPolygonLayerToMap(this.selectedField.id, true);
    }

    this.removeFieldFromMap(this.designatedFieldId);

    this.map.easeTo({ pitch: 45, duration: 1000 });

    this.draw.deleteAll();
    this.draw.changeMode(this.draw.modes.SIMPLE_SELECT);
  }

  private drawingOnMapFieldDesignationDesignatingHandler(): void {
    if (this.selectedField !== undefined) {
      this.removeFieldPolygonLayerFromMap(this.selectedField.id);
      this.addFieldPolygonLayerToMap(this.selectedField.id, false);
    }

    this.map.easeTo({ pitch: 0, duration: 1000 });

    this.draw.deleteAll();
    this.draw.changeMode(this.draw.modes.DRAW_POLYGON);
  }

  private drawingOnMapFieldDesignationDesignatedHandler(): void {
    this.removeFieldFromMap(this.designatedFieldId);

    const data = this.draw.getAll();
    // Azért kell ez a vizsgálat, hogy ha csak 2 vagy kevesebb pontot jelölünk ki és resetelni akarjuk, akkor ne törjön el a mapbox
    if (
      data.features.length === 0 ||
      data.features[0].geometry.coordinates[0].length < 5
    ) {
      this.snackbarService.openErrorSnackBar(
        this.translateService.instant(
          'dashboard-page.add-field-panel.invalid-field',
        ),
      );
      return;
    }
    const featureId = data.features[0].id;
    this.drawingOnMapDesignatedFieldFeatureId = featureId;
    this.draw.changeMode(this.draw.modes.DIRECT_SELECT, {
      featureId: this.drawingOnMapDesignatedFieldFeatureId,
    });
  }

  private drawingOnMapFieldDesignationNamingFieldHandler(): void {
    this.draw.changeMode(this.draw.modes.SIMPLE_SELECT);
    const coordinates = this.transformFieldCoordinatesToCoordinateArray(
      this.designatedFieldCoordinates,
    );
    this.addFieldToMap(this.designatedFieldId, coordinates, true);
  }

  private gpsFieldDesignatioUnselectedHandler(): void {
    if (this.selectedField !== undefined) {
      this.removeFieldPolygonLayerFromMap(this.selectedField.id);
      this.addFieldPolygonLayerToMap(this.selectedField.id, true);
    }

    this.removeFieldFromMap(this.designatedFieldId);

    this.map.easeTo({ pitch: 45, duration: 1000 });

    this.draw.deleteAll();
    this.draw.changeMode(this.draw.modes.SIMPLE_SELECT);
    this.gpsFieldDesignationCoordinates = [[]];
  }

  private gpsFieldDesignationInitHandler(): void {
    this.draw.deleteAll();
    this.gpsFieldDesignationCoordinates = [[]];

    this.gpsPermissionDenied = false;
    this.startGPSFieldDesignationDisabled = true;

    if (navigator.geolocation) {
      if (this.selectedField !== undefined) {
        this.removeFieldPolygonLayerFromMap(this.selectedField.id);
        this.addFieldPolygonLayerToMap(this.selectedField.id, false);
      }

      this.map.easeTo({ pitch: 0, duration: 1000 });

      navigator.geolocation.getCurrentPosition(
        (position: GeolocationPosition) => {
          this.lng = position.coords.longitude;
          this.lat = position.coords.latitude;

          this.geolocate.trigger();

          this.startGPSFieldDesignationDisabled = false;
        },
        (error: GeolocationPositionError) => {
          if (error.code === error.PERMISSION_DENIED) {
            window.alert(
              'Location is not allowed. Please go to Settings and allow it for being able to use this function.',
            );

            this.gpsPermissionDenied = true;
          } else if (error.code === error.POSITION_UNAVAILABLE) {
            window.alert('Location not found.');
          }

          console.error(error.message);
        },
      );
    }
  }

  private gpsFieldDesignationDesignatingHandler(): void {
    this.map.easeTo({
      center: [this.lng, this.lat],
      essential: true,
      pitch: 0,
    });

    this.draw.changeMode(this.draw.modes.SIMPLE_SELECT, {
      featureIds: [this.gpsDesignatedFieldFeatureId],
    });

    this.watchPositionHandlerId = navigator.geolocation.watchPosition(
      (position: GeolocationPosition) => {
        const lng: number = position.coords.longitude;
        const lat: number = position.coords.latitude;

        if (this.gpsFieldDesignationCoordinates[0].length === 0) {
          this.updateGPSDesignatedPolygon(lng, lat);
        } else {
          // distance is in kilometers
          const distance: number = turf.distance(
            this.gpsFieldDesignationCoordinates[0][
              this.gpsFieldDesignationCoordinates[0].length - 2
            ],
            [lng, lat],
          );

          // distance threshold is 10 meters = 0.01 kilometers
          if (distance > 0.01) {
            this.updateGPSDesignatedPolygon(lng, lat);
          }
        }
      },
    );

    // SIMULATION OF GPS DESIGNATION
    // const delta = 0.0005;
    // const polygonCoordinates = [];

    // for (let index = 0; index < 10; index++) {
    //   this.lng += delta;
    //   polygonCoordinates.push([this.lng, this.lat]);
    // }
    // for (let index = 0; index < 10; index++) {
    //   this.lat += delta;
    //   polygonCoordinates.push([this.lng, this.lat]);
    // }
    // for (let index = 0; index < 10; index++) {
    //   this.lng -= delta;
    //   polygonCoordinates.push([this.lng, this.lat]);
    // }
    // for (let index = 0; index < 10; index++) {
    //   this.lat -= delta;
    //   polygonCoordinates.push([this.lng, this.lat]);
    // }

    // let index = 0;
    // this.intervalId = setInterval(() => {
    //   const polygonCoordinate = polygonCoordinates[index];
    //   const lng = polygonCoordinate[0];
    //   const lat = polygonCoordinate[1];

    //   if (this.gpsFieldDesignationCoordinates[0].length === 0) {
    //     this.updateGPSDesignatedPolygon(lng, lat);
    //     // this.putMarkerToMap(lng, lat);
    //   }
    //   else {
    //     // distance is in kilometers
    // tslint:disable-next-line:max-line-length
    //     const distance = turf.distance(this.gpsFieldDesignationCoordinates[0][this.gpsFieldDesignationCoordinates[0].length - 2], [lng, lat]);

    //     // distance threshold is 10 meters = 0.01 kilometers
    //     if (distance > 0.01) {
    //       this.updateGPSDesignatedPolygon(lng, lat);
    //       // this.putMarkerToMap(lng, lat);
    //     }
    //   }

    //   index++;

    //   if (index === polygonCoordinates.length) {
    //       clearInterval(this.intervalId);
    //   }
    // }, 1000);
  }

  private updateGPSDesignatedPolygon(lng, lat): void {
    if (this.gpsFieldDesignationCoordinates[0].length === 0) {
      this.gpsFieldDesignationCoordinates[0].push([lng, lat]);
      this.gpsFieldDesignationCoordinates[0].push([lng, lat]);
    } else {
      this.gpsFieldDesignationCoordinates[0].splice(
        this.gpsFieldDesignationCoordinates[0].length - 1,
        0,
        [lng, lat],
      );
    }

    const feature = {
      id: this.gpsDesignatedFieldFeatureId,
      type: 'Feature',
      properties: {},
      geometry: {
        type: 'Polygon',
        coordinates: this.gpsFieldDesignationCoordinates,
      },
    };
    const featureIds = this.draw.add(feature);
  }

  private gpsFieldDesignationDesignatedHandler(): void {
    navigator.geolocation.clearWatch(this.watchPositionHandlerId);
    // clearInterval(this.intervalId);

    this.removeFieldFromMap(this.designatedFieldId);

    this.draw.changeMode(this.draw.modes.DIRECT_SELECT, {
      featureId: this.gpsDesignatedFieldFeatureId,
    });

    this.updateSelectedPolygon(this.draw.getAll());
  }

  private gpsFieldDesignationNamingFieldHandler(): void {
    this.draw.changeMode(this.draw.modes.SIMPLE_SELECT);
    const coordinates = this.transformFieldCoordinatesToCoordinateArray(
      this.designatedFieldCoordinates,
    );
    this.addFieldToMap(this.designatedFieldId, coordinates, true);
  }

  private selectSensorDataLocation(
    location: Location,
    deviceType: DeviceTypeEnum,
  ): void {
    this.dashboardStore.dispatch(
      DashboardActions.selectSensorDataLocation({
        sensorDataLocation: {
          ...location,
          deviceType,
        },
      }),
    );

    this.selectedSensorDataLocation = location;
    this.selectedDeviceType = deviceType;
  }

  private unselectSensorDataLocation(): void {
    this.dashboardStore.dispatch(
      DashboardActions.selectSensorDataLocation({
        sensorDataLocation: undefined,
      }),
    );

    this.selectedSensorDataLocation = undefined;
    this.selectedDeviceType = undefined;
  }

  private selectFirstSensorDataLocation(
    sensorDataLocations: SensorDataLocations,
  ): void {
    if (sensorDataLocations.gatewayDataLocations.length > 0) {
      this.selectSensorDataLocation(
        sensorDataLocations.gatewayDataLocations[0],
        DeviceTypeEnum.Gateway,
      );
      this.selectSensorDataLocationMarker(
        sensorDataLocations.gatewayDataLocations[0],
        DeviceTypeEnum.Gateway,
      );
    } else if (sensorDataLocations.nodeDataLocations.length > 0) {
      this.selectSensorDataLocation(
        sensorDataLocations.nodeDataLocations[0],
        DeviceTypeEnum.Node,
      );
      this.selectSensorDataLocationMarker(
        sensorDataLocations.nodeDataLocations[0],
        DeviceTypeEnum.Node,
      );
    }
  }

  private putMarkerToMap(
    location: Location,
    deviceType: string,
    onClickListener,
  ): void {
    const markerHtmlId =
      'marker-' +
      location.coordinate.longitude.toString() +
      location.coordinate.latitude.toString();
    const el: HTMLDivElement = document.createElement('div');
    const width: number = 30;
    const height: number = 47.69;
    el.className = 'marker';
    el.id = markerHtmlId;

    switch (deviceType) {
      case DeviceTypeEnum.Gateway:
        el.style.backgroundImage = `url("${this.assetsUrl}/markers/gateway-marker.svg")`;
        break;
      case DeviceTypeEnum.Node:
        el.style.backgroundImage = `url("${this.assetsUrl}/markers/node-marker.svg")`;
        break;
      case DeviceTypeEnum.WateringDevice:
        el.style.backgroundImage = `url("${this.assetsUrl}/markers/linear-marker.svg")`;
    }

    el.style.width = `${width}px`;
    el.style.height = `${height}px`;

    const marker = new mapboxgl.Marker(el)
      .setLngLat([location.coordinate.longitude, location.coordinate.latitude])
      .setOffset([0, -47.69 / 2])
      .addTo(this.map); // add the marker to the map

    marker.getElement().addEventListener('click', onClickListener);
    marker.getElement().addEventListener('touchstart', onClickListener);

    this.markers.push(marker);
  }

  private selectSensorDataLocationMarker(
    location: Location,
    deviceType: DeviceTypeEnum,
  ): void {
    const markerHtmlId: string =
      'marker-' +
      location.coordinate.longitude.toString() +
      location.coordinate.latitude.toString();

    document.getElementById(markerHtmlId).style.backgroundImage =
      deviceType === DeviceTypeEnum.Gateway
        ? `url("${this.assetsUrl}/markers/selected-gateway-marker.svg")`
        : `url("${this.assetsUrl}/markers/selected-node-marker.svg")`;
  }

  private unselectSensorDataLocationMarker(): void {
    if (this.selectedSensorDataLocation === undefined) {
      return;
    }

    const markerHtmlId: string =
      'marker-' +
      this.selectedSensorDataLocation.coordinate.longitude.toString() +
      this.selectedSensorDataLocation.coordinate.latitude.toString();

    document.getElementById(markerHtmlId).style.backgroundImage =
      this.selectedDeviceType === DeviceTypeEnum.Gateway
        ? `url("${this.assetsUrl}/markers/gateway-marker.svg")`
        : `url("${this.assetsUrl}/markers/node-marker.svg")`;
  }

  private clearMarkers(): void {
    this.markers.forEach(marker => {
      marker.getElement().removeAllListeners();
      marker.remove();
    });

    this.markers = [];
  }

  private _beforePutMarkerToMap(): void {}

  private _afterPutMarkerToMap(): void {}
}
