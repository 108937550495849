<form [formGroup]="loginFormGroup" class="login__container">
  <div class="login__container__inner">
    <div class="logo">
      <img src="../../../../../assets/dfarm-logo-2.svg" alt="logo"/>
    </div>
    <h1 class="login__title">{{ 'login-page.login' | translate }}</h1>
    <label class="login__label"> {{ 'login-page.email' | translate }} </label>
    <input
      name="email"
      type="email"
      placeholder="something@example.com"
      [formControl]="formControls.username"
    />
    <label class="login__label">
      {{ 'login-page.password' | translate }}
    </label>
    <input
      name="password"
      type="password"
      placeholder="*******"
      [formControl]="formControls.password"
    />
    <div class="flex flex-col gap-2">
      <dfarm-button
        (click)="login()"
        type="submit"
        [outlineButton]="true">
        {{ 'login-page.login' | translate }}
      </dfarm-button>
      <dfarm-button
        (click)="register()"
        [ghostButton]="true">
        {{ 'login-page.registration' | translate }}
      </dfarm-button>
      <dfarm-button
        (click)="forgotPassword()"
        [ghostButton]="true">
        {{ 'login-page.forgot-password' | translate }}
      </dfarm-button>
      <dfarm-language-selector></dfarm-language-selector>
    </div>
  </div>
</form>
