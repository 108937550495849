import { Component, OnInit } from '@angular/core';

@Component({
	selector: "dfarm-parcell-details",
	templateUrl: "./parcell-details.component.html",
	styleUrls: ["./parcell-details.component.scss"],
})
export class ParcellDetailsComponent implements OnInit {
	constructor() {}

	ngOnInit(): void {}
}
