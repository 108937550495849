<div
  class="login__container"
  *ngIf="!newPasswordFormGroup.controls.token.value && !isSplashScreen">
  <div class="login__container__inner">
    <div class="logo">
      <img src="../../../../../assets/dfarm-logo-2.svg" alt="logo"/>
    </div>
    <h1 class="forgotten-password-title">
      {{ 'forgotten-password-page.forgotten-password' | translate }}
    </h1>
    <label class="forgotten-password-label">
      {{ 'forgotten-password-page.user-account-email' | translate }}
    </label>
    <input
      name="email"
      type="email"
      [formControl]="email"
      placeholder="valaki@pelda.hu"/>
    <div class="flex flex-col gap-2">
      <dfarm-button
        (click)="requestNewPassword()"
        [outlineButton]="true">
        {{ 'forgotten-password-page.request-new-password' | translate }}
      </dfarm-button>
      <dfarm-button
        (click)="navigateToLogin()"
        [ghostButton]="true">
        {{ 'forgotten-password-page.login' | translate }}
      </dfarm-button>
    </div>
  </div>
</div>

<div
  class="login__container"
  *ngIf="newPasswordFormGroup.controls.token.value && !isSplashScreen">
  <form [formGroup]="newPasswordFormGroup" class="login__container__inner">
    <div class="logo">
      <img src="../../../../../assets/dfarm-logo-2.svg" alt="logo"/>
    </div>
    <h1 class="login__title">Új jelszó beállítása</h1>
    <div class="input-wrapper">
      <label
        class="field-name-label"
        [ngClass]="{
          'error-text':
            (formControls.password.touched && formControls.password.invalid) ||
            (isSubmitted && formControls.password.invalid)
        }">
        Új jelszó
      </label>
      <input
        name="password"
        type="password"
        placeholder="********"
        [ngClass]="{
          'error-input':
            (formControls.password.touched && formControls.password.invalid) ||
            (isSubmitted && formControls.password.invalid)
        }"
        [formControl]="formControls.password"/>
    </div>
    <div
      *ngIf="
        (formControls.password.touched && formControls.password.invalid) ||
        (isSubmitted && formControls.password.invalid)
      "
      class="password-requirement">
      <div>Jelszó feltételek:</div>
      <ul>
        <li>Minimum 8 karakter</li>
        <li>Kis- és nagybetű</li>
        <li>Minimum egy darab szám</li>
        <li>Minimum egy darab speciális karakter: &#64; $ ! % * ? & . - _</li>
      </ul>
    </div>
    <div class="input-wrapper">
      <label
        class="field-name-label"
        [ngClass]="{
          'error-text':
            (formControls.passwordAgain.touched &&
              formControls.passwordAgain.invalid) ||
            (isSubmitted && formControls.passwordAgain.invalid)
        }">
        Új jelszó megerősítése
      </label>
      <input
        name="passwordAgain"
        type="password"
        placeholder="********"
        [ngClass]="{
          'error-input':
            (formControls.passwordAgain.touched &&
              formControls.passwordAgain.invalid) ||
            (isSubmitted && formControls.passwordAgain.invalid)
        }"
        [formControl]="formControls.passwordAgain"/>
    </div>
    <div
      *ngIf="
        (formControls.passwordAgain.touched &&
          formControls.passwordAgain.value !== formControls.password.value) ||
        (isSubmitted &&
          formControls.passwordAgain.value !== formControls.password.value)
      "
      class="password-requirement">
      <div>A jelszó megerősítése nem egyezik.</div>
    </div>
    <div class="flex flex-col gap-2">
      <dfarm-button
        (click)="setNewPassword()"
        [outlineButton]="true">
        Jelszó beállítása
      </dfarm-button>
      <dfarm-button
        (click)="navigateToLogin()"
        [ghostButton]="true">
        Bejelentkezés
      </dfarm-button>
    </div>
  </form>
</div>

<div class="login__container" *ngIf="isSplashScreen">
  <div class="login__container__inner">
    <div class="logo">
      <img src="../../../../../assets/dfarm-logo-2.svg" alt="logo"/>
    </div>
    <h1 class="login__title text-center">
      {{ splashScreenText }}
    </h1>
    <dfarm-button
      (click)="navigateToLogin()"
      [outlineButton]="true">
      Bejelentkezés
    </dfarm-button>
  </div>
</div>
