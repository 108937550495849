import { Component, OnInit } from '@angular/core';
import { FormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { SnackbarService } from 'src/app/shared/services/snackbar.service';
import { AuthService } from '../../auth.service';
import { IRegisterUserViewModel } from '../../models/user.model';
import { TranslateService } from '@ngx-translate/core';

// import { filter } from 'rxjs/add/operator/filter';

@Component({
  selector: 'dfarm-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.scss'],
})
export class RegisterComponent implements OnInit {
  formControls: Record<keyof IRegisterUserViewModel, FormControl> = {
    email: new FormControl('', [Validators.required, Validators.email]),
    emailAgain: new FormControl('', [Validators.required, Validators.email]),
    phoneNumber: new FormControl('', Validators.required),
    password: new FormControl('', [
      Validators.required,
      Validators.pattern(
        '(?=[^A-Z]*[A-Z])(?=[^a-z]*[a-z])(?=[^0-9]*[0-9])(?=.*[\\W_]).{8,}',
      ),
    ]),
    passwordAgain: new FormControl('', [
      Validators.required,
      Validators.pattern(
        '(?=[^A-Z]*[A-Z])(?=[^a-z]*[a-z])(?=[^0-9]*[0-9])(?=.*[\\W_]).{8,}',
      ),
    ]),
    firstName: new FormControl('', Validators.required),
    lastName: new FormControl('', Validators.required),
    termsAndConditions: new FormControl(false, Validators.requiredTrue),
    // token: new FormControl(false, Validators.required),
  };
  registerFormGroup = new UntypedFormGroup(this.formControls);

  isSubmitted: boolean = false;
  isRegisteredSuccessfully: boolean = false;
  isRegisterFailed: boolean = false;

  constructor(
    private router: Router,
    private authService: AuthService,
    private translateService: TranslateService,
    private snackbarService: SnackbarService,
  ) {}

  ngOnInit(): void {
    // this.route.queryParams.subscribe((params) => {
    //   if (params) {
    //     this.registerFormGroup.controls.token.setValue(params.Token);
    //   }
    // });
  }

  register(): void {
    this.isSubmitted = true;
    if (this.generalInfoValid && this.passwordIsMatch && this.emailIsMatch) {
      this.authService.registerUser(this.registerFormGroup.value).subscribe(
        () => (this.isRegisteredSuccessfully = true),
        (error) => {
          this.snackbarService.openErrorSnackBar(error.error.errorMessage);
          this.isRegisterFailed = true;
        },
      );
    }
  }

  navigateToLoginPage(): void {
    this.router.navigate(['/login']);
  }

  navigateToRegisterPage(): void {
    this.router.navigate(['/registration']).then(() => {
      window.location.reload();
    });
  }

  resendConfirmationEmail(): void {
    this.authService
      .resendConfirmationEmail(this.registerFormGroup.controls.email.value)
      .subscribe(
        () => {
          this.snackbarService.openSuccessSnackBar(
            this.translateService.instant(
              'registration-page.resend-email-successfully',
            ),
          );
        },
        (error) => {
          if (error && error.error && error.error.errorMessage) {
            this.snackbarService.openErrorSnackBar(error.error.errorMessage);
          }
        },
      );
  }

  private get generalInfoValid(): boolean {
    return (
      this.registerFormGroup.controls.email.valid &&
      this.registerFormGroup.controls.firstName.valid &&
      this.registerFormGroup.controls.phoneNumber.valid &&
      this.registerFormGroup.controls.termsAndConditions.value === true &&
      // this.registerFormGroup.controls.token.valid &&
      this.registerFormGroup.controls.lastName.valid
    );
  }

  private get passwordIsMatch(): boolean {
    return (
      this.registerFormGroup.controls.password.value ===
      this.registerFormGroup.controls.passwordAgain.value &&
      this.registerFormGroup.controls.password.value.length >= 8
    );
  }

  private get emailIsMatch(): boolean {
    return (
      this.registerFormGroup.controls.email.value ===
      this.registerFormGroup.controls.emailAgain.value
    );
  }
}
