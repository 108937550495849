import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { SnackbarService } from 'src/app/shared/services/snackbar.service';
import { AuthService } from '../../auth.service';

@Component({
  selector: 'dfarm-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss'],
})
export class ForgotPasswordComponent implements OnInit {
  isSplashScreen = false;
  splashScreenText = '';
  email = new FormControl('', [Validators.required, Validators.email]);
  formControls: Record<keyof any, FormControl> = {
    token: new FormControl('', Validators.required),
    password: new FormControl('', [
      Validators.required,
      Validators.pattern(
        '(?=[^A-Z]*[A-Z])(?=[^a-z]*[a-z])(?=[^0-9]*[0-9])(?=.*[@$!%*?&.-_]).{8,}'
      ),
    ]),
    passwordAgain: new FormControl('', [
      Validators.required,
      Validators.pattern(
        '(?=[^A-Z]*[A-Z])(?=[^a-z]*[a-z])(?=[^0-9]*[0-9])(?=.*[@$!%*?&.-_]).{8,}'
      ),
    ]),
  };
  newPasswordFormGroup = new FormGroup(this.formControls);

  isSubmitted = false;

  constructor(
    private router: Router,
    private authService: AuthService,
    private route: ActivatedRoute,
    private snackbarService: SnackbarService
  ) {}

  ngOnInit(): void {
    this.route.queryParams.subscribe((params) => {
      if (params) {
        this.newPasswordFormGroup.controls.token.setValue(params.Token);
      }
    });
  }

  requestNewPassword(): void {
    if (this.email.valid) {
      this.authService.requestPassword(this.email.value).subscribe(
        () => {
          this.isSplashScreen = true;
          this.splashScreenText =
            'Új jelszó igénylése sikeres megtörtént. Kérjük, ellenőrizd a megadott email fiókod.';
        },
        (error) =>
          this.snackbarService.openErrorSnackBar(error.error.errorMessage)
      );
    }
  }

  setNewPassword(): void {
    this.isSubmitted = true;
    if (this.passwordIsMatch) {
      this.authService
        .setNewPassword(
          this.newPasswordFormGroup.controls.token.value,
          this.newPasswordFormGroup.controls.password.value
        )
        .subscribe(
          // () => this.navigateToLogin(),
          () => {
            this.isSplashScreen = true;
            this.splashScreenText = 'Új jelszó beállítása sikeres megtörtént.';
          },
          (error) =>
            this.snackbarService.openErrorSnackBar(error.error.errorMessage)
        );
    }
  }

  navigateToLogin(): void {
    this.router.navigate(['/login']);
  }

  private get passwordIsMatch(): boolean {
    return (
      this.newPasswordFormGroup.controls.password.value ===
        this.newPasswordFormGroup.controls.passwordAgain.value &&
      this.newPasswordFormGroup.controls.password.value.length >= 8
    );
  }
}
